unit SharedDataModule;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Modules,
  Data.DB,
  XData.Web.Client,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Connection,
  WEBLib.ExtCtrls,
  SimpleDashboard;

type
  TSharedData = class(TDataModule)
    JobsTimer: TTimer;
    JobsDataset: TXDataWebDataSet;
    JobsDatasetId: TIntegerField;
    JobsDatasetDateAdded: TDateTimeField;
    JobsDatasetAddedBy: TIntegerField;
    JobsDatasetLastUpdatedBy: TIntegerField;
    JobsDatasetLastUpdated: TDateTimeField;
    JobsDatasetJobType: TStringField;
    JobsDatasetSubmittedBy: TIntegerField;
    JobsDatasetDateSubmitted: TDateTimeField;
    JobsDatasetStatus: TStringField;
    JobsDatasetTimeTaken: TFloatField;
    JobsDatasetDateRun: TDateTimeField;
    JobsDatasetVisibility: TStringField;
    JobsDatasetData: TStringField;
    JobsDatasetStats: TStringField;
    JobsDatasetDownloads: TStringField;
    JobsDatasetOutputOption: TStringField;
    JobsDatasetCompletedBy: TIntegerField;
    JobsDatasetOwnedBy: TIntegerField;
    JobsDatasetTitle: TStringField;
    JobsDatasetNotifyOnCompletion: TBooleanField;
    JobsDatasetDateCompleted: TDateTimeField;
    JobsDatasetHasErrors: TBooleanField;
    JobsDatasetProgress: TFloatField;
    JobsDatasetRunLevel: TStringField;
    JobsDatasetJobCategory: TStringField;
    JobsDatasetLastPolled: TDateTimeField;
    JobsDatasetPollInterval: TLargeintField;
    JobsDatasetPollCount: TIntegerField;
    procedure JobsDatasetAfterOpen(DataSet: TDataSet);
    procedure JobsDatasetBeforeOpen(DataSet: TDataSet);
  private
    { Private declarations }
    FWebClient: TXDataWebClient;
    FJobsLocked: Boolean;
    FSimpleDashboard: TSimpleDashboard;
    procedure SetDataConnection(const Value: TXDataWebConnection);
    procedure SetWebClient(const Value: TXDataWebClient);
  public
    { Public declarations }
    constructor Create(AOwner: TComponent; AWebClient: TXDataWebClient); reintroduce;
    destructor Destroy; override;
    procedure LockJobsData;
    procedure UnlockJobsData(const ARefresh: Boolean = False);
    procedure RefreshJobs;
    procedure LoadJobs;
    property WebClient: TXDataWebClient read FWebClient write SetWebClient;
    property DataConnection: TXDataWebConnection write SetDataConnection;
    property Dashboard: TSimpleDashboard read FSimpleDashboard;
  protected procedure LoadDFMValues; override; end;

var
  SharedData: TSharedData;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

uses
  MainDataModule;

{$R *.dfm}

{ TSharedData }

constructor TSharedData.Create(AOwner: TComponent; AWebClient: TXDataWebClient);
begin
  inherited Create(AOwner);
  FWebClient := AWebClient;
  FSimpleDashboard := TSimpleDashboard.Create(FWebClient);
end;

destructor TSharedData.Destroy;
begin
  FSimpleDashboard.Free;
  inherited;
end;

procedure TSharedData.JobsDatasetAfterOpen(DataSet: TDataSet);
begin
  JobsTimer.Enabled := False;
end;

procedure TSharedData.JobsDatasetBeforeOpen(DataSet: TDataSet);
begin
  JobsTimer.Enabled := False;
end;

procedure TSharedData.LoadJobs;
begin
  JobsTimer.Enabled := False;
  FJobsLocked := True;
  JobsDataset.DisableControls;
  JobsDataset.Close;
  JobsDataset.Load;


  if JobsDataset.RecordCount = 0 then
    JobsTimer.Enabled := False;



end;

procedure TSharedData.LockJobsData;
begin
  JobsTimer.Enabled := False;
  FJobsLocked := True;
end;

procedure TSharedData.RefreshJobs;
begin
  LoadJobs;
end;

procedure TSharedData.SetDataConnection(const Value: TXDataWebConnection);
begin
  JobsDataset.Connection := Value;
end;

procedure TSharedData.SetWebClient(const Value: TXDataWebClient);
begin
  FWebClient := Value;
  FSimpleDashboard.WebClient := FWebClient;
end;

procedure TSharedData.UnlockJobsData(const ARefresh: Boolean);
begin
  if ARefresh then
    RefreshJobs
  else
  begin
    FJobsLocked := False;
    JobsTimer.Enabled := True;
  end;
end;

procedure TSharedData.LoadDFMValues;
begin
  inherited LoadDFMValues;

  JobsTimer := TTimer.Create(Self);
  JobsDataset := TXDataWebDataSet.Create(Self);
  JobsDatasetId := TIntegerField.Create(Self);
  JobsDatasetDateAdded := TDateTimeField.Create(Self);
  JobsDatasetAddedBy := TIntegerField.Create(Self);
  JobsDatasetLastUpdatedBy := TIntegerField.Create(Self);
  JobsDatasetLastUpdated := TDateTimeField.Create(Self);
  JobsDatasetJobType := TStringField.Create(Self);
  JobsDatasetSubmittedBy := TIntegerField.Create(Self);
  JobsDatasetDateSubmitted := TDateTimeField.Create(Self);
  JobsDatasetStatus := TStringField.Create(Self);
  JobsDatasetTimeTaken := TFloatField.Create(Self);
  JobsDatasetDateRun := TDateTimeField.Create(Self);
  JobsDatasetVisibility := TStringField.Create(Self);
  JobsDatasetData := TStringField.Create(Self);
  JobsDatasetStats := TStringField.Create(Self);
  JobsDatasetDownloads := TStringField.Create(Self);
  JobsDatasetOutputOption := TStringField.Create(Self);
  JobsDatasetCompletedBy := TIntegerField.Create(Self);
  JobsDatasetOwnedBy := TIntegerField.Create(Self);
  JobsDatasetTitle := TStringField.Create(Self);
  JobsDatasetNotifyOnCompletion := TBooleanField.Create(Self);
  JobsDatasetDateCompleted := TDateTimeField.Create(Self);
  JobsDatasetHasErrors := TBooleanField.Create(Self);
  JobsDatasetProgress := TFloatField.Create(Self);
  JobsDatasetRunLevel := TStringField.Create(Self);
  JobsDatasetJobCategory := TStringField.Create(Self);
  JobsDatasetLastPolled := TDateTimeField.Create(Self);
  JobsDatasetPollInterval := TLargeintField.Create(Self);
  JobsDatasetPollCount := TIntegerField.Create(Self);

  JobsTimer.BeforeLoadDFMValues;
  JobsDataset.BeforeLoadDFMValues;
  JobsDatasetId.BeforeLoadDFMValues;
  JobsDatasetDateAdded.BeforeLoadDFMValues;
  JobsDatasetAddedBy.BeforeLoadDFMValues;
  JobsDatasetLastUpdatedBy.BeforeLoadDFMValues;
  JobsDatasetLastUpdated.BeforeLoadDFMValues;
  JobsDatasetJobType.BeforeLoadDFMValues;
  JobsDatasetSubmittedBy.BeforeLoadDFMValues;
  JobsDatasetDateSubmitted.BeforeLoadDFMValues;
  JobsDatasetStatus.BeforeLoadDFMValues;
  JobsDatasetTimeTaken.BeforeLoadDFMValues;
  JobsDatasetDateRun.BeforeLoadDFMValues;
  JobsDatasetVisibility.BeforeLoadDFMValues;
  JobsDatasetData.BeforeLoadDFMValues;
  JobsDatasetStats.BeforeLoadDFMValues;
  JobsDatasetDownloads.BeforeLoadDFMValues;
  JobsDatasetOutputOption.BeforeLoadDFMValues;
  JobsDatasetCompletedBy.BeforeLoadDFMValues;
  JobsDatasetOwnedBy.BeforeLoadDFMValues;
  JobsDatasetTitle.BeforeLoadDFMValues;
  JobsDatasetNotifyOnCompletion.BeforeLoadDFMValues;
  JobsDatasetDateCompleted.BeforeLoadDFMValues;
  JobsDatasetHasErrors.BeforeLoadDFMValues;
  JobsDatasetProgress.BeforeLoadDFMValues;
  JobsDatasetRunLevel.BeforeLoadDFMValues;
  JobsDatasetJobCategory.BeforeLoadDFMValues;
  JobsDatasetLastPolled.BeforeLoadDFMValues;
  JobsDatasetPollInterval.BeforeLoadDFMValues;
  JobsDatasetPollCount.BeforeLoadDFMValues;
  try
    Name := 'SharedData';
    Height := 263;
    Width := 419;
    JobsTimer.SetParentComponent(Self);
    JobsTimer.Name := 'JobsTimer';
    JobsTimer.Enabled := False;
    JobsTimer.Interval := 30000;
    JobsTimer.Left := 31;
    JobsTimer.Top := 22;
    JobsDataset.SetParentComponent(Self);
    JobsDataset.Name := 'JobsDataset';
    JobsDataset.BeforeOpen := JobsDatasetBeforeOpen;
    JobsDataset.AfterOpen := JobsDatasetAfterOpen;
    JobsDataset.EntitySetName := 'Job';
    JobsDataset.Left := 247;
    JobsDataset.Top := 40;
    JobsDatasetId.SetParentComponent(JobsDataset);
    JobsDatasetId.Name := 'JobsDatasetId';
    JobsDatasetId.FieldName := 'Id';
    JobsDatasetId.Required := True;
    JobsDatasetDateAdded.SetParentComponent(JobsDataset);
    JobsDatasetDateAdded.Name := 'JobsDatasetDateAdded';
    JobsDatasetDateAdded.FieldName := 'DateAdded';
    JobsDatasetDateAdded.Required := True;
    JobsDatasetAddedBy.SetParentComponent(JobsDataset);
    JobsDatasetAddedBy.Name := 'JobsDatasetAddedBy';
    JobsDatasetAddedBy.FieldName := 'AddedBy';
    JobsDatasetAddedBy.Required := True;
    JobsDatasetLastUpdatedBy.SetParentComponent(JobsDataset);
    JobsDatasetLastUpdatedBy.Name := 'JobsDatasetLastUpdatedBy';
    JobsDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    JobsDatasetLastUpdated.SetParentComponent(JobsDataset);
    JobsDatasetLastUpdated.Name := 'JobsDatasetLastUpdated';
    JobsDatasetLastUpdated.FieldName := 'LastUpdated';
    JobsDatasetJobType.SetParentComponent(JobsDataset);
    JobsDatasetJobType.Name := 'JobsDatasetJobType';
    JobsDatasetJobType.FieldName := 'JobType';
    JobsDatasetJobType.Size := 25;
    JobsDatasetSubmittedBy.SetParentComponent(JobsDataset);
    JobsDatasetSubmittedBy.Name := 'JobsDatasetSubmittedBy';
    JobsDatasetSubmittedBy.FieldName := 'SubmittedBy';
    JobsDatasetDateSubmitted.SetParentComponent(JobsDataset);
    JobsDatasetDateSubmitted.Name := 'JobsDatasetDateSubmitted';
    JobsDatasetDateSubmitted.FieldName := 'DateSubmitted';
    JobsDatasetStatus.SetParentComponent(JobsDataset);
    JobsDatasetStatus.Name := 'JobsDatasetStatus';
    JobsDatasetStatus.FieldName := 'Status';
    JobsDatasetStatus.Size := 16;
    JobsDatasetTimeTaken.SetParentComponent(JobsDataset);
    JobsDatasetTimeTaken.Name := 'JobsDatasetTimeTaken';
    JobsDatasetTimeTaken.FieldName := 'TimeTaken';
    JobsDatasetDateRun.SetParentComponent(JobsDataset);
    JobsDatasetDateRun.Name := 'JobsDatasetDateRun';
    JobsDatasetDateRun.FieldName := 'DateRun';
    JobsDatasetVisibility.SetParentComponent(JobsDataset);
    JobsDatasetVisibility.Name := 'JobsDatasetVisibility';
    JobsDatasetVisibility.FieldName := 'Visibility';
    JobsDatasetVisibility.Size := 16;
    JobsDatasetData.SetParentComponent(JobsDataset);
    JobsDatasetData.Name := 'JobsDatasetData';
    JobsDatasetData.FieldName := 'Data';
    JobsDatasetData.Size := 4096;
    JobsDatasetStats.SetParentComponent(JobsDataset);
    JobsDatasetStats.Name := 'JobsDatasetStats';
    JobsDatasetStats.FieldName := 'Stats';
    JobsDatasetStats.Size := 4096;
    JobsDatasetDownloads.SetParentComponent(JobsDataset);
    JobsDatasetDownloads.Name := 'JobsDatasetDownloads';
    JobsDatasetDownloads.FieldName := 'Downloads';
    JobsDatasetDownloads.Size := 4096;
    JobsDatasetOutputOption.SetParentComponent(JobsDataset);
    JobsDatasetOutputOption.Name := 'JobsDatasetOutputOption';
    JobsDatasetOutputOption.FieldName := 'OutputOption';
    JobsDatasetOutputOption.Size := 50;
    JobsDatasetCompletedBy.SetParentComponent(JobsDataset);
    JobsDatasetCompletedBy.Name := 'JobsDatasetCompletedBy';
    JobsDatasetCompletedBy.FieldName := 'CompletedBy';
    JobsDatasetOwnedBy.SetParentComponent(JobsDataset);
    JobsDatasetOwnedBy.Name := 'JobsDatasetOwnedBy';
    JobsDatasetOwnedBy.FieldName := 'OwnedBy';
    JobsDatasetTitle.SetParentComponent(JobsDataset);
    JobsDatasetTitle.Name := 'JobsDatasetTitle';
    JobsDatasetTitle.FieldName := 'Title';
    JobsDatasetTitle.Size := 255;
    JobsDatasetNotifyOnCompletion.SetParentComponent(JobsDataset);
    JobsDatasetNotifyOnCompletion.Name := 'JobsDatasetNotifyOnCompletion';
    JobsDatasetNotifyOnCompletion.FieldName := 'NotifyOnCompletion';
    JobsDatasetDateCompleted.SetParentComponent(JobsDataset);
    JobsDatasetDateCompleted.Name := 'JobsDatasetDateCompleted';
    JobsDatasetDateCompleted.FieldName := 'DateCompleted';
    JobsDatasetHasErrors.SetParentComponent(JobsDataset);
    JobsDatasetHasErrors.Name := 'JobsDatasetHasErrors';
    JobsDatasetHasErrors.FieldName := 'HasErrors';
    JobsDatasetProgress.SetParentComponent(JobsDataset);
    JobsDatasetProgress.Name := 'JobsDatasetProgress';
    JobsDatasetProgress.FieldName := 'Progress';
    JobsDatasetRunLevel.SetParentComponent(JobsDataset);
    JobsDatasetRunLevel.Name := 'JobsDatasetRunLevel';
    JobsDatasetRunLevel.FieldName := 'RunLevel';
    JobsDatasetRunLevel.Size := 10;
    JobsDatasetJobCategory.SetParentComponent(JobsDataset);
    JobsDatasetJobCategory.Name := 'JobsDatasetJobCategory';
    JobsDatasetJobCategory.FieldName := 'JobCategory';
    JobsDatasetJobCategory.Size := 8;
    JobsDatasetLastPolled.SetParentComponent(JobsDataset);
    JobsDatasetLastPolled.Name := 'JobsDatasetLastPolled';
    JobsDatasetLastPolled.FieldName := 'LastPolled';
    JobsDatasetPollInterval.SetParentComponent(JobsDataset);
    JobsDatasetPollInterval.Name := 'JobsDatasetPollInterval';
    JobsDatasetPollInterval.FieldName := 'PollInterval';
    JobsDatasetPollCount.SetParentComponent(JobsDataset);
    JobsDatasetPollCount.Name := 'JobsDatasetPollCount';
    JobsDatasetPollCount.FieldName := 'PollCount';
  finally
    JobsTimer.AfterLoadDFMValues;
    JobsDataset.AfterLoadDFMValues;
    JobsDatasetId.AfterLoadDFMValues;
    JobsDatasetDateAdded.AfterLoadDFMValues;
    JobsDatasetAddedBy.AfterLoadDFMValues;
    JobsDatasetLastUpdatedBy.AfterLoadDFMValues;
    JobsDatasetLastUpdated.AfterLoadDFMValues;
    JobsDatasetJobType.AfterLoadDFMValues;
    JobsDatasetSubmittedBy.AfterLoadDFMValues;
    JobsDatasetDateSubmitted.AfterLoadDFMValues;
    JobsDatasetStatus.AfterLoadDFMValues;
    JobsDatasetTimeTaken.AfterLoadDFMValues;
    JobsDatasetDateRun.AfterLoadDFMValues;
    JobsDatasetVisibility.AfterLoadDFMValues;
    JobsDatasetData.AfterLoadDFMValues;
    JobsDatasetStats.AfterLoadDFMValues;
    JobsDatasetDownloads.AfterLoadDFMValues;
    JobsDatasetOutputOption.AfterLoadDFMValues;
    JobsDatasetCompletedBy.AfterLoadDFMValues;
    JobsDatasetOwnedBy.AfterLoadDFMValues;
    JobsDatasetTitle.AfterLoadDFMValues;
    JobsDatasetNotifyOnCompletion.AfterLoadDFMValues;
    JobsDatasetDateCompleted.AfterLoadDFMValues;
    JobsDatasetHasErrors.AfterLoadDFMValues;
    JobsDatasetProgress.AfterLoadDFMValues;
    JobsDatasetRunLevel.AfterLoadDFMValues;
    JobsDatasetJobCategory.AfterLoadDFMValues;
    JobsDatasetLastPolled.AfterLoadDFMValues;
    JobsDatasetPollInterval.AfterLoadDFMValues;
    JobsDatasetPollCount.AfterLoadDFMValues;
  end;
end;

end.
