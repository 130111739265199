unit ImportList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  smx.webcore.types;

type
  TImportListForm = class(TBaseListForm)
    ImportDialog: TMessageDlg;
    RefreshTimer: TTimer;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetBatchResolution: TStringField;
    MainDatasetBatchState: TStringField;
    MainDatasetRecordsImported: TIntegerField;
    MainDatasetValidRecords: TIntegerField;
    MainDatasetErrorRecords: TIntegerField;
    MainDatasetMatched: TIntegerField;
    MainDatasetMerged: TIntegerField;
    MainDatasetSourceType: TStringField;
    ImportCount: TTimer;
    MainDatasetSourceDescription: TStringField;
    MainDatasetSourceFile: TStringField;
    procedure WebFormDestroy(Sender: TObject);
    procedure AddButtonClick(Sender: TObject);
    procedure DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer;
        AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure ImportCountTimer(Sender: TObject);
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure MainDatasetAfterOpen(Dataset: TDataSet);
    procedure MainDatasetAfterPost(DataSet: TDataSet);
    procedure MainDatasetCalcFields(DataSet: TDataSet);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    FMergeId: string;
    FBatchId: integer;
    FOutstandingImports: integer;
    FAllowMerge: boolean;
    [async]
    procedure MergeErrorDataJob(const ARecordId: string); async;
    [async]
    procedure ShowImportData(const ARecordId: string); async;
    [async]
    procedure ShowMergedData(const ARecordId: string); async;
    [async]
    procedure DownloadErrorFile(const ARecordId: string); async;

    procedure EnableRefreshTimer(const AnInterval: Integer = 1000);
    procedure RefreshControls;

    [async]
    procedure AddNewImport; async;
    [async]
    procedure CheckAllImportsCompleted; async;
    [async]
    procedure GetGridProgressData; async;
    procedure UpdateGridWithValues(AJSONData: string);
    procedure SetImportBatchToCancelling(ARecordId: string);
  protected
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    procedure AddRecord(const ARecordId: string); override;
    function CreateGridInFormCreate: boolean; override;

    [async]
    procedure MergeDataJob(const ARecordId: string); async;
    [async]
    procedure CancelImportJob(const ARecordId: string); async;
    procedure ViewData(const ARecordId: string);
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
    procedure PrepareForm; override;
    procedure LoadData; override;
    function GetEditFormClass: TFormClass; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ImportListForm: TImportListForm;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  WEBLib.WebCtrls,
  ImportBatch,
  ImportForm,
  MainDataModule,
  ImportData,
  DonorList,
  DonationList, SMX.Web.Service.Consts, SMX.Web.Layout.Utils;

procedure TImportListForm.WebFormDestroy(Sender: TObject);
begin
  //Make sure timers off before navigating off the form.
  //GridRefresh.Enabled := False;
  RefreshTimer.Enabled := False;
  ImportCount.Enabled := False;
  inherited;
end;

procedure TImportListForm.AddButtonClick(Sender: TObject);
begin
  inherited;
end;

{ TImportListForm }

procedure TImportListForm.AddRecord(const ARecordId: string);
begin
  AddNewImport;
end;

procedure TImportListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure ViewDataClick(Sender: TObject);
  begin
    ViewData(ARecordId);
  end;

  procedure MergeDataClick(Sender: TObject);
  begin
    MergeDataJob(ARecordId);
  end;

  procedure MergeErrorDataClick(Sender: TObject);
  begin
    MergeErrorDataJob(ARecordId);
  end;

  procedure CancelImportClick(Sender: TObject);
  begin
    CancelImportJob(ARecordId);
  end;

  procedure DownloadErrorFileClick(Sender: TObject);
  begin
    DownloadErrorFile(ARecordId);
  end;

var
  Span: THTMLSpan;
  BatchState: string;
  NoOfErrors: integer;
begin
  // (New, Processing, Errors, Valid, Merging, Merged
  BatchState := MainDataset.FieldByName('BatchState').AsString;
  NoOfErrors := MainDataSet.FieldByName('ErrorRecords').AsInteger;

  if (BatchState = 'Valid') or (BatchState = 'Errors') then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-table', 'View data');
    Span.OnClick := @ViewDataClick;

    if NoOfErrors > 0 then
    begin
      Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-tags', 'Merge data excluding errors');
      Span.OnClick := @MergeErrorDataClick;

      Span := TLayoutUtils.RowActionSpan(AParentElement, 'fa-solid fa-file-csv', 'Download Error File');
      Span.OnClick := @DownloadErrorFileClick;
    end
    else
    begin
      Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-tags', 'Merge data');
      Span.OnClick := @MergeDataClick;
    end;

    if (BatchState <> 'Cancelled') then
    begin
      Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-ban', 'Cancel import');
      Span.OnClick := @CancelImportClick;
    end;
  end
  else if (BatchState = 'Merged') then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-table', 'View data');
    Span.OnClick := @ViewDataClick;
  end
  else if (BatchState = 'Failed') then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-ban', 'Cancel import');
    Span.OnClick := @CancelImportClick;
  end;

end;

procedure TImportListForm.SetImportBatchToCancelling(ARecordId: string);
begin
  if MainDataset.Locate('Id', ARecordId, []) then
  begin
    MainDataset.Edit;
    MainDataset.FieldByName('BatchState').AsString := 'Cancelling';
    MainDataset.Post;
  end;
end;

procedure TImportListForm.CancelImportJob(const ARecordId: string);
var
  lJobParams: JS.TJSObject;
  lRetval: TXDataClientResponse;
  JobId: Integer;
begin
  SetImportBatchToCancelling(ARecordId);

  if not MainDataset.Locate('Id', ARecordId, []) then
    Exit;

  lJobParams := JS.TJSObject.new;
  lJobParams.Properties['ImportId'] := ARecordId.ToInteger;

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULEJOB,
    ['TImportCancelJob', 'AnyAdminUser', JS.TJSJSON.stringify(lJobParams), 'Undecided', 'Production']));

  JobId := JS.ToInteger(lRetval.ResultAsObject['value']);

  EnableRefreshTimer(1000);

  ShowAToast('Import Cancellation', Format('The job to cancel your import has been placed. The Job Number is %d',
    [JobId]), 'clock.png');
end;

procedure TImportListForm.EnableRefreshTimer(const AnInterval: Integer);
begin
  if RefreshTimer.Enabled then
    Exit;
  RefreshTimer.Interval := AnInterval;
  RefreshTimer.Enabled := True;
end;

function TImportListForm.GetEditFormClass: TFormClass;
begin
  Result := TImportBatchForm;
end;

function TImportListForm.InitSortCol: Integer;
begin
  Result := 0;
end;

function TImportListForm.InitSortDirection: TSortDirection;
begin
  Result := TSortDirection.sdDesc;
end;

procedure TImportListForm.LoadData;
begin
  CreateGridPlugin;
  FMergeId := '';
end;

procedure TImportListForm.MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  inherited;
  if FMergeId <> '' then
  begin
    MergeDataJob(FMergeId);
    FMergeId := '';
    EnableRefreshTimer;
  end;
end;

procedure TImportListForm.MainDatasetAfterOpen(Dataset: TDataSet);
begin
  inherited;
  MainDataset.EnableControls;
end;

procedure TImportListForm.MergeDataJob(const ARecordId: string);
var
  lJobParams: JS.TJSObject;
  lRetval: TXDataClientResponse;
  JobId: Integer;
begin
  if FAllowMerge then
  begin
    FAllowMerge := False;
    lJobParams := JS.TJSObject.new;
    lJobParams.Properties['ImportId'] := ARecordId.ToInteger;

    lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULEJOB,
      ['TImportMergeJob', 'AnyAdminUser', JS.TJSJSON.stringify(lJobParams), 'Undecided', 'Production']));

    JobId := JS.ToInteger(lRetval.ResultAsObject['value']);

    EnableRefreshTimer(1000);

    ShowAToast('Data Merge', Format('The job to merge your import data has been placed. The Job Number is %d', [JobId]),
      'clock.png');
    ImportCount.Enabled := True;
  end;
end;

procedure TImportListForm.MergeErrorDataJob(const ARecordId: string);
var
  mr: TModalResult;
begin
  if FAllowMerge then
  begin
    mr := await(TModalResult, ImportDialog.ShowDialog('Do you want to ignore the errors and import?',
      WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));
    if mr = mrYes then
    begin
      FMergeId := ARecordId;
      MainDataset.Edit;
      MainDataset.FieldByName('BatchResolution').AsString := 'Remove';
      MainDataset.Post;
    end;
  end;
end;

procedure TImportListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

procedure TImportListForm.RefreshControls;
var
  FilterStr: string;
begin
//  FGridPlugin.SetPreparedFilter('ClaimStatus eq Unclaimed');
  FGridPlugin.Load;
  FAllowMerge := True;
end;


procedure TImportListForm.RefreshTimerTimer(Sender: TObject);
begin
  RefreshControls;
  RefreshTimer.Enabled := False;
  GetGridProgressData;
end;

procedure TImportListForm.ShowImportData(const ARecordId: string);
var
  AForm: TImportDataList;
begin
  AForm := TImportDataList.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    AForm.ImportId := ARecordId.ToInteger;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
    EnableRefreshTimer(1000);
  end;
end;

procedure TImportListForm.ShowMergedData(const ARecordId: string);
var
  AForm: TDonations_List;
begin
  AForm := TDonations_List.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    AForm.ImportId := ARecordId.ToInteger;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TImportListForm.ViewData(const ARecordId: string);
var
  BatchState: string;
begin
  if MainDataset.Locate('Id', ARecordId, []) then
  begin
    BatchState := MainDataset.FieldByName('BatchState').AsString;
    if BatchState = 'Merged' then
      ShowMergedData(ARecordId)
    else if (BatchState = 'Valid') or (BatchState = 'Errors') then
      ShowImportData(ARecordId)
    else
      ShowMessage('This data isn''t available for viewing at the moment');
  end;
end;

procedure TImportListForm.WebFormShow(Sender: TObject);
begin
  inherited;
  LoadData;
  RefreshControls;
  ImportCount.Enabled := True;
end;

procedure TImportListForm.AddNewImport;
var
  AForm: TImportPage;

begin
  AForm := TImportPage.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
    EnableRefreshTimer(1000);
    ImportCount.Enabled := true;
  end;
end;

function TImportListForm.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

procedure TImportListForm.CheckAllImportsCompleted;
var
  lRetval: TXDataClientResponse;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.ImportsOutstanding', []));
  FOutstandingImports := JS.ToInteger(lRetval.ResultAsObject['value']);
end;

procedure TImportListForm.DataTableGetCellChildren(Sender: TObject; ACol, ARow:
    Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
begin
  inherited;

  if ((ACol = 4) and (ARow <> 0)) and
     ((DataTable.Cells[4, ARow] = 'New') or (DataTable.Cells[4, ARow] = 'Processing')) then
    DataTable.Cells[4, ARow] := 'Importing ...';


end;

procedure TImportListForm.ImportCountTimer(Sender: TObject);
begin
  GetGridProgressData;
end;

procedure TImportListForm.MainDatasetCalcFields(DataSet: TDataSet);
var
  lText: string;
begin
  inherited;

  lText := MainDatasetSourceType.Value;
  if lText = 'Import' then
     MainDatasetSourceDescription.Value := 'File Import'
  else if lText = 'GAImport' then
     MainDatasetSourceDescription.Value := 'GA Desktop'
  else if lText = 'Manual' then
     MainDatasetSourceDescription.Value := 'Manual Data Entry'
  else
     MainDatasetSourceDescription.Value := lText;

end;

procedure TImportListForm.GetGridProgressData;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  JSONFileData: string;

begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.GetGridProgressData',[]));
  lResult := lRetval.ResultAsObject;
  JSONFileData := JS.ToString(lResult['value']);

  if JSONFileData = 'unrecoginsed-encoding' then
  begin
    //ShowMessage(SInvalidFileEncoding);
    Exit;
  end;

  UpdateGridWithValues(JSONFileData);
end;

procedure TImportListForm.MainDatasetAfterPost(DataSet: TDataSet);
begin
  inherited;
  EnableRefreshTimer(1000);
end;

procedure TImportListForm.UpdateGridWithValues(AJSONData: string);
var
  I: integer;
  nI: integer;
  sI: string;

  JSImportData: TJSObject;
  JSRowList: TJSArray;
  lArrayIdx: integer;
  lArrayCount: integer;
  lDataRow: string;
  DataRowSL: TStringList;
  DataValue: string;
  lBatchState: string;
  lImportFileName: string;
  lImportCount: string;
  lErrorCount: string;
  lMergedCount: string;
  lUpdateRowIdx: integer;
  lComplete: string;


  function FindRowToUpdate(lImportFileName: string):integer;
  var
    lRowIdx: integer;
    lRowCount: integer;
    lCurrentRowIdx: integer;
  begin
    lRowIdx := -1;
    lRowCount := MainDataset.RecordCount;

    for lCurrentRowIdx := 1 to lRowCount do
    begin
      if (lImportFileName = DataTable.Cells[6, lCurrentRowIdx]) and
        ((DataTable.Cells[5, lCurrentRowIdx] = 'Importing ...') or
         (DataTable.Cells[5, lCurrentRowIdx] = 'Merging') or
         (DataTable.Cells[5, lCurrentRowIdx] = 'New') or
         (DataTable.Cells[5, lCurrentRowIdx] = 'Processing') or
         (DataTable.Cells[5, lCurrentRowIdx] = 'Valid') or
         (DataTable.Cells[5, lCurrentRowIdx] = 'Cancelling')) then
      begin
        lRowIdx := lCurrentRowIdx;
        break;
      end;
    end;
    result := lRowIdx;
  end;


begin
  if AJSONData <> '' then
  begin
    JSImportData := TJSJSON.parseObject(AJSONData);
    lComplete := string(JSImportData['Complete']);

    JSRowList := TJSArray(JSImportData['Data']);
    lArrayCount := JSRowList.Length;

    DataRowSL := TStringList.Create;
    try
      try
        DataRowSL.Delimiter := ',';
        DataRowSL.StrictDelimiter := true;

        for lArrayIdx := 0 to lArrayCount-1 do
        begin
          lDataRow := string(JSRowList[lArrayIdx]);
          DataRowSL.DelimitedText := lDataRow;

          for I := 0 to lArrayCount-1 do
          begin
            lBatchState := DataRowSL[0];

            if lBatchState = 'Failed' then
            begin
              RefreshControls;
            end
            else
            begin
              lImportFileName := DataRowSL[1];
              lImportCount := DataRowSL[2];
              lErrorCount := DataRowSL[3];
              lMergedCount := DataRowSL[4];

              lUpdateRowIdx := FindRowToUpdate(lImportFileName);

              if lUpdateRowIdx <> -1 then
              begin
                DataTable.Cells[2, lUpdateRowIdx] := lImportCount;
                DataTable.Cells[3, lUpdateRowIdx] := lErrorCount;
                DataTable.Cells[4, lUpdateRowIdx] := lMergedCount;
                DataTable.Cells[5, lUpdateRowIdx] := lBatchState;
              end;
            end;
          end;
        end;
      except

      end;
    finally
      if lComplete = 'T' then
      begin
        ImportCount.Enabled := false;
        RefreshControls;
      end;

      DataRowSL.Free;
    end;
  end
  else
  begin
    RefreshControls;
  end;
end;

procedure TImportListForm.DownloadErrorFile(const ARecordId: string);
var
  lFileName, lFile: string;
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  JSONFileData: string;
  lErrorFileName: string;
  lErrorFileData: string;
  ErrorFile: TStringList;
  lSourceFile: string;
  lPos: integer;
begin
  if MainDataset.Locate('Id', ARecordId, []) then
  begin
    lSourceFile := MainDataset.FieldByName('SourceFile').AsString;

    lSourceFile := StringReplace(lSourceFile, '.xlsx', '.csv', []);
    lSourceFile := StringReplace(lSourceFile, '.xls', '.csv', []);

    lErrorFileName := lSourceFile;
    lPos := Pos('.csv', lErrorFileName);
    lErrorFileName.Insert(lPos-1, '_ImportErrors');

    lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderService.GetErrorFile',
      [lErrorFileName]));
    //lResult := lRetval.ResultAsObject;
    //  lErrorFileData := JS.ToString(lResult['value']);

    lErrorFileData  := JS.ToString(lRetval.ResultAsObject['value']);
    //lErrorFileName := Format('GiftAider-ClaimRef_%d.csv', [AId]);

    Application.DownloadTextFile(lErrorFileData, lErrorFileName);
  end;
end;

procedure TImportListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ImportDialog := TMessageDlg.Create(Self);
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetBatchResolution := TStringField.Create(Self);
  MainDatasetBatchState := TStringField.Create(Self);
  MainDatasetRecordsImported := TIntegerField.Create(Self);
  MainDatasetValidRecords := TIntegerField.Create(Self);
  MainDatasetErrorRecords := TIntegerField.Create(Self);
  MainDatasetMatched := TIntegerField.Create(Self);
  MainDatasetMerged := TIntegerField.Create(Self);
  MainDatasetSourceType := TStringField.Create(Self);
  MainDatasetSourceDescription := TStringField.Create(Self);
  MainDatasetSourceFile := TStringField.Create(Self);
  RefreshTimer := TTimer.Create(Self);
  ImportCount := TTimer.Create(Self);

  DataTable.BeforeLoadDFMValues;
  ImportDialog.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetBatchResolution.BeforeLoadDFMValues;
  MainDatasetBatchState.BeforeLoadDFMValues;
  MainDatasetRecordsImported.BeforeLoadDFMValues;
  MainDatasetValidRecords.BeforeLoadDFMValues;
  MainDatasetErrorRecords.BeforeLoadDFMValues;
  MainDatasetMatched.BeforeLoadDFMValues;
  MainDatasetMerged.BeforeLoadDFMValues;
  MainDatasetSourceType.BeforeLoadDFMValues;
  MainDatasetSourceDescription.BeforeLoadDFMValues;
  MainDatasetSourceFile.BeforeLoadDFMValues;
  MainDataSource.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  ImportCount.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DataTable.Top := 110;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'DateAdded';
      Title := 'Date';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'SourceDescription';
      Title := 'Source';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'RecordsImported';
      Title := 'Imported';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ErrorRecords';
      Title := 'Errors';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Merged';
      Title := 'Merged';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'BatchState';
      Title := 'State';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'SourceFile';
      Title := 'Source File';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    ImportDialog.SetParentComponent(Self);
    ImportDialog.Name := 'ImportDialog';
    ImportDialog.Left := 584;
    ImportDialog.Top := 136;
    ImportDialog.Width := 24;
    ImportDialog.Height := 24;
    ImportDialog.HeightStyle := ssAuto;
    ImportDialog.WidthStyle := ssAuto;
    ImportDialog.Buttons := [];
    ImportDialog.Opacity := 0.200000000000000000;
    ImportDialog.ElementButtonClassName := 'btn';
    ImportDialog.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    ImportDialog.ElementTitleClassName := 'text-body';
    ImportDialog.ElementContentClassName := 'text-body';
    MainDataset.AfterApplyUpdates := MainDatasetAfterApplyUpdates;
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    MainDataset.EntitySetName := 'ImportBatch';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetBatchResolution.SetParentComponent(MainDataset);
    MainDatasetBatchResolution.Name := 'MainDatasetBatchResolution';
    MainDatasetBatchResolution.FieldName := 'BatchResolution';
    MainDatasetBatchResolution.Required := True;
    MainDatasetBatchResolution.Size := 6;
    MainDatasetBatchState.SetParentComponent(MainDataset);
    MainDatasetBatchState.Name := 'MainDatasetBatchState';
    MainDatasetBatchState.FieldName := 'BatchState';
    MainDatasetBatchState.Required := True;
    MainDatasetBatchState.Size := 10;
    MainDatasetRecordsImported.SetParentComponent(MainDataset);
    MainDatasetRecordsImported.Name := 'MainDatasetRecordsImported';
    MainDatasetRecordsImported.FieldName := 'RecordsImported';
    MainDatasetRecordsImported.Required := True;
    MainDatasetValidRecords.SetParentComponent(MainDataset);
    MainDatasetValidRecords.Name := 'MainDatasetValidRecords';
    MainDatasetValidRecords.FieldName := 'ValidRecords';
    MainDatasetValidRecords.Required := True;
    MainDatasetErrorRecords.SetParentComponent(MainDataset);
    MainDatasetErrorRecords.Name := 'MainDatasetErrorRecords';
    MainDatasetErrorRecords.FieldName := 'ErrorRecords';
    MainDatasetErrorRecords.Required := True;
    MainDatasetMatched.SetParentComponent(MainDataset);
    MainDatasetMatched.Name := 'MainDatasetMatched';
    MainDatasetMatched.FieldName := 'Matched';
    MainDatasetMatched.Required := True;
    MainDatasetMerged.SetParentComponent(MainDataset);
    MainDatasetMerged.Name := 'MainDatasetMerged';
    MainDatasetMerged.FieldName := 'Merged';
    MainDatasetMerged.Required := True;
    MainDatasetSourceType.SetParentComponent(MainDataset);
    MainDatasetSourceType.Name := 'MainDatasetSourceType';
    MainDatasetSourceType.FieldName := 'SourceType';
    MainDatasetSourceType.Required := True;
    MainDatasetSourceType.Size := 9;
    MainDatasetSourceDescription.SetParentComponent(MainDataset);
    MainDatasetSourceDescription.Name := 'MainDatasetSourceDescription';
    MainDatasetSourceDescription.FieldKind := fkCalculated;
    MainDatasetSourceDescription.FieldName := 'SourceDescription';
    MainDatasetSourceDescription.Size := 25;
    MainDatasetSourceDescription.Calculated := True;
    MainDatasetSourceFile.SetParentComponent(MainDataset);
    MainDatasetSourceFile.Name := 'MainDatasetSourceFile';
    MainDatasetSourceFile.FieldName := 'SourceFile';
    MainDatasetSourceFile.Size := 75;
    MainDataSource.Left := 168;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 512;
    RefreshTimer.Top := 16;
    ImportCount.SetParentComponent(Self);
    ImportCount.Name := 'ImportCount';
    SetEvent(ImportCount, Self, 'OnTimer', 'ImportCountTimer');
    ImportCount.Left := 96;
    ImportCount.Top := 424;
  finally
    DataTable.AfterLoadDFMValues;
    ImportDialog.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetBatchResolution.AfterLoadDFMValues;
    MainDatasetBatchState.AfterLoadDFMValues;
    MainDatasetRecordsImported.AfterLoadDFMValues;
    MainDatasetValidRecords.AfterLoadDFMValues;
    MainDatasetErrorRecords.AfterLoadDFMValues;
    MainDatasetMatched.AfterLoadDFMValues;
    MainDatasetMerged.AfterLoadDFMValues;
    MainDatasetSourceType.AfterLoadDFMValues;
    MainDatasetSourceDescription.AfterLoadDFMValues;
    MainDatasetSourceFile.AfterLoadDFMValues;
    MainDataSource.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
    ImportCount.AfterLoadDFMValues;
  end;
end;

end.
