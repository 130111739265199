unit MainForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Sphinx.WebLogin,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Toast,
  App.Types,
  WEBLib.WebCtrls, WEBLib.ExtCtrls,
  DashboardForm;

type
  TMainPage = class(TForm)
    DashboardLabel: TLabel;
    ExceptionTestLink: TLabel;
    SupportLink: TLabel;
    LogoutLabel: TLabel;
    LoggedInUserLabel: TLabel;
    MainToast: TToast;
    MainMessageDlg: TMessageDlg;
    GiftAidLink: TLabel;
    NewDonorsLabel: TLabel;
    DonorAdminLabel: TLabel;
    ImportLink: TLabel;
    ManualEntryLink: TLabel;
    UnclaimedDonationsLink: TLabel;
    MakeAClaimLink: TLabel;
    ManualDonationLink: TLabel;
    SysConfigLink: TLabel;
    OtherIncomeLink: TLabel;
    AggregatedDonationsLink: TLabel;
    GASDSLink: TLabel;
    GASDSAdjustmentsLink: TLabel;
    AdjustmentsLink: TLabel;
    CommunityBuildingsLink: TLabel;
    OtherClaimsCommunityBuildings: TLabel;
    ConnectedCharitiesLink: TLabel;
    OrganisationLink: TLabel;
    TenantNameLabel: TLabel;
    userInitials: THTMLSpan;
    UsersAdminLink: TLabel;
    DashboardTimer: TTimer;
    AgentTimer: TTimer;
    JobListRefreshTimer: TTimer;
    ReloadJobsTimer: TTimer;
    procedure AdjustmentsLinkClick(Sender: TObject);
    procedure AgentTimerTimer(Sender: TObject);
    procedure AggregatedDonationsLinkClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure DonorAdminLabelClick(Sender: TObject);
    procedure GAConfigLinkClick(Sender: TObject);
    procedure GASDSAdjustmentsLinkClick(Sender: TObject);
    procedure GASDSLinkClick(Sender: TObject);
    procedure GiftAidLinkClick(Sender: TObject);
    procedure ImportLinkClick(Sender: TObject);
    procedure MakeAClaimLinkClick(Sender: TObject);
    procedure ManualDonationLinkClick(Sender: TObject);
    procedure ManualEntryLinkClick(Sender: TObject);
    procedure OtherIncomeLinkClick(Sender: TObject);
    procedure SysConfigLinkClick(Sender: TObject);
    procedure UnclaimedDonationsLinkClick(Sender: TObject);
    procedure CommunityBuildingsLinkClick(Sender: TObject);
    procedure ConnectedCharitiesLinkClick(Sender: TObject);
    procedure DashboardLabelClick(Sender: TObject);
    procedure DashboardTimerTimer(Sender: TObject);
    procedure ExceptionTestLinkClick(Sender: TObject);
    procedure LogoutLabelClick(Sender: TObject);
    procedure OrganisationLinkClick(Sender: TObject);
    procedure OtherClaimsCommunityBuildingsClick(Sender: TObject);
    procedure UsersAdminLinkClick(Sender: TObject);
    procedure WebFormExit(Sender: TObject);
    procedure JobListRefreshTimerTimer(Sender: TObject);
    procedure ReloadJobsTimerTimer(Sender: TObject);
  private
    FDashboardForm: TDashboard;
    FDisplayedForm: TForm;
    FOrganisationId: integer;
    [async]
    procedure MakeAClaim; async;

    procedure OnFormSelfClose(Sender: TObject);
    // procedure ShowForm(AFormClass: TWebFormClass; AProc: TFormCreatedProc = nil);
    procedure ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc = nil; AlwaysClose: boolean = false);
    function CloseDisplayedForm: boolean;
    [async]
    procedure ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc = nil); async;
    procedure ShowDashboard;
    procedure ErrorHandler(AMessage: string);

    [async]
    procedure SetLabels; async;
    procedure InitialiseData;
    [async]
    function CheckForAgent: integer; async;
    [async]
    procedure DisplayAgentCharities; async;
    procedure ReloadDashboard(AInterval: integer);
    procedure ReloadJobs;

  public
    { Public declarations }
    class procedure DisplayPage(const AElementId: string);

    /// <param name="AIcon">
    /// A FontAwesome icon such as 'fad fa-bug'
    /// </param>
    procedure ShowAToast(const ATitle, AMessage, AIcon: string);
    procedure DisplayMessage(const AMessage: string; DlgType: TMsgDlgType = mtInformation);
    function ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType;
        Buttons: TMsgDlgButtons; const AClassName: string = 'SMXDialogBox'):
        TModalResult;
  protected procedure LoadDFMValues; override; end;

var
  MainPage: TMainPage;

implementation

{$R *.dfm}

uses
  DonorList,
  GiftAidClaimsList,
  ImportList,
  DonationsForm,
  XData.Web.Client,
  MainDataModule,
  GiftAidClaimForm,
  SysConfigForm,
  GAConfigForm,
  ManualEntryList,
  OtherClaimsList,
  OtherClaimsAdjustmentsList,
  CommunityBuildingsList,
  OtherClaimsCommunityBuildingsList,
  ConnectedCharitiesList,
  OtherClaimsConnectedCharitiesList,
  CharityEdit,
  SMX.Auth.Shared,
  SMX.Web.Document.Utils,
  Auth.Service,
  SharedDataModule,
  //DashboardForm,
  WebForm.Core,
  OtherClaimsGASDSList,
  UserManager,
  AgentCharitiesList;

procedure TMainPage.WebFormCreate(Sender: TObject);
begin
  CheckForAgent;
  AgentTimer.Enabled := true;
end;

procedure TMainPage.WebFormExit(Sender: TObject);
begin
end;

{ TMainAppForm }

procedure TMainPage.DisplayMessage(const AMessage: string; DlgType: TMsgDlgType);
begin
  MainMessageDlg.ShowDialog(AMessage, DlgType, [mbOK]);
end;

class procedure TMainPage.DisplayPage(const AElementId: string);
  procedure AfterCreate(AForm: TObject);
  begin
    TMainPage(AForm).SetLabels;
    // TMainAppForm(AForm).SetLinksAuth;
  end;

begin
  if not Assigned(MainPage) then
  begin
    MainPage := TMainPage.CreateNew(AElementId, @AfterCreate);
  end;
end;

procedure TMainPage.DonorAdminLabelClick(Sender: TObject);
begin
  ShowForm(TDonorListForm);
end;

procedure TMainPage.ErrorHandler(AMessage: string);
begin
  ShowAToast('An Error Occurred', AMessage, 'fad fa-bug');
end;

procedure TMainPage.GAConfigLinkClick(Sender: TObject);
begin
  ShowPopUp(TGAConfig);
end;

procedure TMainPage.GiftAidLinkClick(Sender: TObject);
begin
  ShowForm(TGiftAidClaims);
end;

procedure TMainPage.ImportLinkClick(Sender: TObject);
begin
  ShowForm(TImportListForm);
end;

procedure TMainPage.MakeAClaimLinkClick(Sender: TObject);
begin
  MakeAClaim;
end;

procedure TMainPage.ManualEntryLinkClick(Sender: TObject);
begin
  ShowForm(TManualEntryListForm);
end;

procedure TMainPage.UnclaimedDonationsLinkClick(Sender: TObject);

  procedure DonationsCreated(AForm: TObject);
  begin
    TDonationsList(AForm).ShowStatus := 'Unclaimed';
    TDonationsList(AForm).ShowCaption := 'Unclaimed Donations';
  end;

begin
  ShowForm(TDonationsList, @DonationsCreated);
end;

procedure TMainPage.MakeAClaim;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  lClaimDataValid: Boolean;
  lCount: Integer;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderService.UnclaimedDonations', []));
  lResult := TJSObject(lRetval.Result);
  lCount := JS.toInteger(lResult['value']);

  if lCount = 0 then
  begin
    ShowMessage('There are no donations that are unclaimed');
    Exit;
  end;

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderService.ClaimDataValid', []));
  lClaimDataValid := JS.toBoolean(lRetval.ResultAsObject['value']);

  if not lClaimDataValid then
  begin
    ShowMessage('Please complete your charity information first');
//    OrganisationLinkClick(self);
    Exit;
  end;

  ShowPopUp(TGiftAidClaimJob);
end;

procedure TMainPage.ManualDonationLinkClick(Sender: TObject);

  procedure DonationsCreated(AForm: TObject);
  begin
    TDonationsList(AForm).ShowStatus := 'Unclaimed';
    TDonationsList(AForm).ShowCaption := 'Unclaimed Donations';
  end;

begin
  ShowForm(TDonationsList, @DonationsCreated);
end;

procedure TMainPage.SetLabels;
var
  lPath: string;
begin

  LoggedInUserLabel.Caption := MainData.AppUser.GivenName;
  TenantNameLabel.Caption := MainData.AppUser.OrganisationName;

  lPath := 'tenants/' + AuthService.GetClaimValue(CLAIM_TENANT_ID) + '/tenant-logo.png';

  TDocUtils.writeImageSrc('tenant-logo', lPath);

  UserInitials.HTML.Text := MainData.AppUser.Initials;
  MakeAClaimLink.Enabled := MainData.AppUser.CanSubmit;
  UsersAdminLink.Enabled := MainData.AppUser.IsAdmin; // AuthService.IsAdministrator;
  OrganisationLink.Enabled := UsersAdminLink.Enabled;

end;

procedure TMainPage.ShowAToast(const ATitle, AMessage, AIcon: string);
const
  MESSAGE_TEMPLATE = '<span class="ToastMessage">%s</span>';
  MESSAGE_TITLE = '<i class="%s fa-fw fa-lg"></i> %s';
var
  lToast: TToastItem;
begin
  lToast := MainToast.Items.Add(Format(MESSAGE_TITLE, [AIcon, ATitle]), Format(MESSAGE_TEMPLATE, [AMessage]));
  lToast.Show;
end;

procedure TMainPage.ShowDashboard;

  procedure DashboardCreated(AForm: TObject);
  begin
    FDashboardForm := TDashboard(AForm);
    TDashboard(AForm).JobsDataset := SharedData.JobsDataset;
  end;

begin
  ShowForm(TDashboard, @DashboardCreated, True);
end;

procedure TMainPage.ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc; AlwaysClose: boolean);
var
  lFormName: string;

  procedure AfterFormCreated(AForm: TObject);
  begin

    if AFormClass.InheritsFrom(TCoreWebForm) then
      TCoreWebForm(AForm).OnSelfClose := OnFormSelfClose;

    if Assigned(AProc) then
      AProc(AForm);

  end;

begin
  // Note that MainPanel is no longer a component. This now just uses a div on the
  // template with that id.
  if Assigned(AFormClass) then
  begin
    if not (AFormClass = TDashboard) then
    begin
  asm debugger; end;      ReloadJobsTimer.Enabled := False;
      JobListRefreshTimer.Enabled := False;
    end;
  end;


  if Assigned(FDisplayedForm) and (FDisplayedForm.ClassType = AFormClass) and (AlwaysClose = false) then
    Exit;

  if not CloseDisplayedForm then
    Exit;

  Application.CreateForm(AFormClass, 'MainPanel', FDisplayedForm, @AfterFormCreated);
end;

procedure TMainPage.ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc);
var
  AForm: TForm;
  lRetval: TModalResult;
begin
  AForm := AFormClass.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TForm, AForm.Load());
    lRetval := await(TModalResult, AForm.Execute);
    if (lRetval = mrOK) and (OnSuccessProc <> nil) then
      OnSuccessProc();
  finally
    AForm.Free;
    AForm := nil;
    ReloadDashboard(5);
  end;
end;

procedure TMainPage.SysConfigLinkClick(Sender: TObject);
begin
  ShowPopUp(TSysConfig);
end;

procedure TMainPage.OtherIncomeLinkClick(Sender: TObject);

  procedure SetOtherClaimsType(AForm: TObject);
  begin
    TOtherClaimsListForm(AForm).OtherClaimsType := 'OtherIncome';
    TOtherClaimsListForm(AForm).OtherClaimsTitle := 'Other Income';
  end;

begin
  ShowForm(TOtherClaimsListForm, @SetOtherClaimsType, True);
end;

procedure TMainPage.AggregatedDonationsLinkClick(Sender: TObject);

  procedure SetOtherClaimsType(AForm: TObject);
  begin
    TOtherClaimsListForm(AForm).OtherClaimsType := 'AggregatedDonations';
    TOtherClaimsListForm(AForm).OtherClaimsTitle := 'Aggregated Donations';
  end;

begin
  ShowForm(TOtherClaimsListForm, @SetOtherClaimsType, True);
end;

procedure TMainPage.GASDSLinkClick(Sender: TObject);
begin
  ShowForm(TOtherClaimsGASDSListForm);
end;

procedure TMainPage.GASDSAdjustmentsLinkClick(Sender: TObject);
  procedure SetOtherClaimsType(AForm: TObject);
  begin
    TOtherClaimsAdjustmentsListForm(AForm).OtherClaimsType := 'GASDSAdjustment';
    TOtherClaimsAdjustmentsListForm(AForm).OtherClaimsTitle := 'GASDS Adjustment';
  end;

begin
  ShowForm(TOtherClaimsAdjustmentsListForm, @SetOtherClaimsType, True);
end;

procedure TMainPage.AdjustmentsLinkClick(Sender: TObject);
  procedure SetOtherClaimsType(AForm: TObject);
  begin
    TOtherClaimsAdjustmentsListForm(AForm).OtherClaimsType := 'Adjustment';
    TOtherClaimsAdjustmentsListForm(AForm).OtherClaimsTitle := 'Adjustment';
  end;

begin
  ShowForm(TOtherClaimsAdjustmentsListForm, @SetOtherClaimsType, True);
end;

function TMainPage.CloseDisplayedForm: boolean;
begin
  Result := True;
  if Assigned(FDisplayedForm) then
  begin
    if FDisplayedForm.ClassType.InheritsFrom(TCoreWebForm) then
    begin
      Result := TCoreWebForm(FDisplayedForm).CanClose;
      if Result then
        TCoreWebForm(FDisplayedForm).TearDown;
    end;
    if not Result then
      Exit;
    FDisplayedForm.Free;
    FDisplayedForm := nil;
  end;
end;

procedure TMainPage.CommunityBuildingsLinkClick(Sender: TObject);
begin
  ShowForm(TCommunityBuildingsListForm);
end;

procedure TMainPage.OtherClaimsCommunityBuildingsClick(Sender: TObject);
begin
  ShowForm(TOtherClaimsCommunityBuildingsListForm);
end;

function TMainPage.ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType;
  Buttons: TMsgDlgButtons; const AClassName: string = 'SMXDialogBox'): TModalResult;
begin
//  MainMessageDlg.ElementDialogClassName := AClassName;
  Result := MainMessageDlg.ShowDialog(AMessage, DlgType, Buttons, nil);
end;

procedure TMainPage.ConnectedCharitiesLinkClick(Sender: TObject);
begin
  ShowForm(TConnectedCharitiesListForm);
end;

procedure TMainPage.DashboardLabelClick(Sender: TObject);
begin
  DashboardTimer.Enabled := True;
end;

procedure TMainPage.DashboardTimerTimer(Sender: TObject);
begin
  DashboardTimer.Enabled := False;
  ShowDashboard;
  ReloadJobsTimer.Interval := 1000;
  ReloadJobsTimer.Enabled := True;
end;

procedure TMainPage.ExceptionTestLinkClick(Sender: TObject);
begin
  raise Exception.Create('Test Exception Raised');
end;

procedure TMainPage.LogoutLabelClick(Sender: TObject);
begin
  MainData.Logout;
end;

procedure TMainPage.OnFormSelfClose(Sender: TObject);
begin
  ShowDashboard;
end;

procedure TMainPage.OrganisationLinkClick(Sender: TObject);
begin
  ShowForm(TCharityEditForm);
end;

procedure TMainPage.UsersAdminLinkClick(Sender: TObject);
begin
  ShowForm(TUserManagerList);
end;

function TMainPage.CheckForAgent: integer;
var
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
  lTenantId: string;

begin
  lTenantId := MainData.TenantId;
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IOrganisationService.IsAgent', [lTenantId]));
  lResponse := lRetval.ResultAsObject;
  FOrganisationId := integer(JS.toInteger(lResponse['value']));
end;

procedure TMainPage.AgentTimerTimer(Sender: TObject);
begin
  AgentTimer.Enabled := False;

  if FOrganisationId = 0 then
  begin
    InitialiseData;
  end
  else
  begin
    DisplayAgentCharities;
  end;
end;

procedure TMainPage.InitialiseData;
begin
  MainData.ShowMessageProc := DisplayMessage;
  MainData.ErrorProc := ErrorHandler;
  SharedData.JobsDataset.DisableControls;
  SharedData.LoadJobs;

  DashboardTimer.Enabled := true;
end;

procedure TMainPage.DisplayAgentCharities;
var
  AForm: TAgentCharitiesListForm;
  lRetval: TModalResult;
begin
  AForm := TAgentCharitiesListForm.Create(Self);

  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    AForm.OrganisationId := FOrganisationId;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    MainData.TenantId := AForm.SelectedTenantId;
    AForm.Free;
    AForm := nil;
    InitialiseData
  end;
end;

procedure TMainPage.ReloadDashboard(AInterval: integer);
begin
  ReloadJobs;
end;

procedure TMainPage.ReloadJobs;
begin
  SharedData.UnlockJobsData(True);
  SharedData.LoadJobs;
  JobListRefreshTimer.Enabled := True;
end;

procedure TMainPage.ReloadJobsTimerTimer(Sender: TObject);
begin
  ReloadJobsTimer.Interval := 10000;
  ReloadJobs;
  JobListRefreshTimer.Enabled := True;
end;

procedure TMainPage.JobListRefreshTimerTimer(Sender: TObject);
var
  lName: string;
begin
  JobListRefreshTimer.Enabled := False;
  if assigned(FDashboardForm) then
    TDashboard(FDashboardForm).RefreshJobsList;
end;


procedure TMainPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DashboardLabel := TLabel.Create('DashboardLink');
  ExceptionTestLink := TLabel.Create('ExceptionTestLink');
  SupportLink := TLabel.Create('SupportLink');
  LogoutLabel := TLabel.Create('LogoutLink');
  LoggedInUserLabel := TLabel.Create('UserNameLabel');
  GiftAidLink := TLabel.Create('GiftAidLink');
  NewDonorsLabel := TLabel.Create('NewDonorsLink');
  DonorAdminLabel := TLabel.Create('DonorAdminLink');
  ImportLink := TLabel.Create('ImportLink');
  ManualEntryLink := TLabel.Create('ManualEntryLink');
  UnclaimedDonationsLink := TLabel.Create('UnclaimedDonationsLink');
  MakeAClaimLink := TLabel.Create('MakeAClaimLink');
  ManualDonationLink := TLabel.Create('ManualDonationLink');
  SysConfigLink := TLabel.Create('SysConfigLink');
  OtherIncomeLink := TLabel.Create('OtherIncome');
  AggregatedDonationsLink := TLabel.Create('AggregatedDonations');
  GASDSLink := TLabel.Create('GASDS');
  GASDSAdjustmentsLink := TLabel.Create('GASDSAdjustments');
  AdjustmentsLink := TLabel.Create('Adjustments');
  CommunityBuildingsLink := TLabel.Create('communityBuildingsLink');
  OtherClaimsCommunityBuildings := TLabel.Create('OtherClaimsCommunityBuildings');
  ConnectedCharitiesLink := TLabel.Create('ConnectedCharitiesLink');
  OrganisationLink := TLabel.Create('OrganisationLink');
  TenantNameLabel := TLabel.Create('TenantNameLabel');
  UsersAdminLink := TLabel.Create('UsersAdminLink');
  MainMessageDlg := TMessageDlg.Create(Self);
  userInitials := THTMLSpan.Create('userInitials');
  MainToast := TToast.Create(Self);
  DashboardTimer := TTimer.Create(Self);
  AgentTimer := TTimer.Create(Self);
  JobListRefreshTimer := TTimer.Create(Self);
  ReloadJobsTimer := TTimer.Create(Self);

  DashboardLabel.BeforeLoadDFMValues;
  ExceptionTestLink.BeforeLoadDFMValues;
  SupportLink.BeforeLoadDFMValues;
  LogoutLabel.BeforeLoadDFMValues;
  LoggedInUserLabel.BeforeLoadDFMValues;
  GiftAidLink.BeforeLoadDFMValues;
  NewDonorsLabel.BeforeLoadDFMValues;
  DonorAdminLabel.BeforeLoadDFMValues;
  ImportLink.BeforeLoadDFMValues;
  ManualEntryLink.BeforeLoadDFMValues;
  UnclaimedDonationsLink.BeforeLoadDFMValues;
  MakeAClaimLink.BeforeLoadDFMValues;
  ManualDonationLink.BeforeLoadDFMValues;
  SysConfigLink.BeforeLoadDFMValues;
  OtherIncomeLink.BeforeLoadDFMValues;
  AggregatedDonationsLink.BeforeLoadDFMValues;
  GASDSLink.BeforeLoadDFMValues;
  GASDSAdjustmentsLink.BeforeLoadDFMValues;
  AdjustmentsLink.BeforeLoadDFMValues;
  CommunityBuildingsLink.BeforeLoadDFMValues;
  OtherClaimsCommunityBuildings.BeforeLoadDFMValues;
  ConnectedCharitiesLink.BeforeLoadDFMValues;
  OrganisationLink.BeforeLoadDFMValues;
  TenantNameLabel.BeforeLoadDFMValues;
  UsersAdminLink.BeforeLoadDFMValues;
  MainMessageDlg.BeforeLoadDFMValues;
  userInitials.BeforeLoadDFMValues;
  MainToast.BeforeLoadDFMValues;
  DashboardTimer.BeforeLoadDFMValues;
  AgentTimer.BeforeLoadDFMValues;
  JobListRefreshTimer.BeforeLoadDFMValues;
  ReloadJobsTimer.BeforeLoadDFMValues;
  try
    Name := 'MainPage';
    Width := 802;
    Height := 718;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnExit', 'WebFormExit');
    DashboardLabel.SetParentComponent(Self);
    DashboardLabel.Name := 'DashboardLabel';
    DashboardLabel.Left := 28;
    DashboardLabel.Top := 8;
    DashboardLabel.Width := 57;
    DashboardLabel.Height := 15;
    DashboardLabel.Cursor := crHandPoint;
    DashboardLabel.Caption := 'Dashboard';
    DashboardLabel.ElementPosition := epIgnore;
    DashboardLabel.HeightStyle := ssAuto;
    DashboardLabel.HeightPercent := 100.000000000000000000;
    DashboardLabel.WidthStyle := ssAuto;
    DashboardLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DashboardLabel, Self, 'OnClick', 'DashboardLabelClick');
    ExceptionTestLink.SetParentComponent(Self);
    ExceptionTestLink.Name := 'ExceptionTestLink';
    ExceptionTestLink.Left := 28;
    ExceptionTestLink.Top := 40;
    ExceptionTestLink.Width := 75;
    ExceptionTestLink.Height := 15;
    ExceptionTestLink.Cursor := crHandPoint;
    ExceptionTestLink.Caption := 'Exception Test';
    ExceptionTestLink.ElementFont := efCSS;
    ExceptionTestLink.HeightStyle := ssAuto;
    ExceptionTestLink.HeightPercent := 100.000000000000000000;
    ExceptionTestLink.WidthPercent := 100.000000000000000000;
    SetEvent(ExceptionTestLink, Self, 'OnClick', 'ExceptionTestLinkClick');
    SupportLink.SetParentComponent(Self);
    SupportLink.Name := 'SupportLink';
    SupportLink.Left := 28;
    SupportLink.Top := 80;
    SupportLink.Width := 42;
    SupportLink.Height := 15;
    SupportLink.Caption := 'Support';
    SupportLink.ElementFont := efCSS;
    SupportLink.ElementPosition := epIgnore;
    SupportLink.HeightStyle := ssAuto;
    SupportLink.HeightPercent := 100.000000000000000000;
    SupportLink.WidthStyle := ssAuto;
    SupportLink.WidthPercent := 100.000000000000000000;
    LogoutLabel.SetParentComponent(Self);
    LogoutLabel.Name := 'LogoutLabel';
    LogoutLabel.Left := 292;
    LogoutLabel.Top := 69;
    LogoutLabel.Width := 38;
    LogoutLabel.Height := 15;
    LogoutLabel.Cursor := crHandPoint;
    LogoutLabel.Caption := 'Logout';
    LogoutLabel.ElementPosition := epIgnore;
    LogoutLabel.HeightStyle := ssAuto;
    LogoutLabel.HeightPercent := 100.000000000000000000;
    LogoutLabel.HTMLType := tDIV;
    LogoutLabel.WidthStyle := ssAuto;
    LogoutLabel.WidthPercent := 100.000000000000000000;
    SetEvent(LogoutLabel, Self, 'OnClick', 'LogoutLabelClick');
    LoggedInUserLabel.SetParentComponent(Self);
    LoggedInUserLabel.Name := 'LoggedInUserLabel';
    LoggedInUserLabel.Left := 292;
    LoggedInUserLabel.Top := 24;
    LoggedInUserLabel.Width := 101;
    LoggedInUserLabel.Height := 15;
    LoggedInUserLabel.Caption := 'LoggedInUserLabel';
    LoggedInUserLabel.ElementPosition := epIgnore;
    LoggedInUserLabel.HeightStyle := ssAuto;
    LoggedInUserLabel.HeightPercent := 100.000000000000000000;
    LoggedInUserLabel.HTMLType := tDIV;
    LoggedInUserLabel.WidthStyle := ssAuto;
    LoggedInUserLabel.WidthPercent := 100.000000000000000000;
    GiftAidLink.SetParentComponent(Self);
    GiftAidLink.Name := 'GiftAidLink';
    GiftAidLink.Left := 28;
    GiftAidLink.Top := 201;
    GiftAidLink.Width := 36;
    GiftAidLink.Height := 15;
    GiftAidLink.Cursor := crHandPoint;
    GiftAidLink.Caption := 'Claims';
    GiftAidLink.ElementFont := efCSS;
    GiftAidLink.HeightStyle := ssAuto;
    GiftAidLink.HeightPercent := 100.000000000000000000;
    GiftAidLink.WidthStyle := ssAuto;
    GiftAidLink.WidthPercent := 100.000000000000000000;
    SetEvent(GiftAidLink, Self, 'OnClick', 'GiftAidLinkClick');
    NewDonorsLabel.SetParentComponent(Self);
    NewDonorsLabel.Name := 'NewDonorsLabel';
    NewDonorsLabel.Left := 28;
    NewDonorsLabel.Top := 168;
    NewDonorsLabel.Width := 65;
    NewDonorsLabel.Height := 15;
    NewDonorsLabel.Cursor := crHandPoint;
    NewDonorsLabel.Caption := 'New Donors';
    NewDonorsLabel.ElementFont := efCSS;
    NewDonorsLabel.HeightStyle := ssAuto;
    NewDonorsLabel.HeightPercent := 100.000000000000000000;
    NewDonorsLabel.Visible := False;
    NewDonorsLabel.WidthPercent := 100.000000000000000000;
    DonorAdminLabel.SetParentComponent(Self);
    DonorAdminLabel.Name := 'DonorAdminLabel';
    DonorAdminLabel.Left := 28;
    DonorAdminLabel.Top := 136;
    DonorAdminLabel.Width := 38;
    DonorAdminLabel.Height := 15;
    DonorAdminLabel.Cursor := crHandPoint;
    DonorAdminLabel.Caption := 'Donors';
    DonorAdminLabel.ElementFont := efCSS;
    DonorAdminLabel.ElementPosition := epIgnore;
    DonorAdminLabel.HeightStyle := ssAuto;
    DonorAdminLabel.HeightPercent := 100.000000000000000000;
    DonorAdminLabel.WidthStyle := ssAuto;
    DonorAdminLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DonorAdminLabel, Self, 'OnClick', 'DonorAdminLabelClick');
    ImportLink.SetParentComponent(Self);
    ImportLink.Name := 'ImportLink';
    ImportLink.Left := 28;
    ImportLink.Top := 114;
    ImportLink.Width := 36;
    ImportLink.Height := 15;
    ImportLink.Cursor := crHandPoint;
    ImportLink.Caption := 'Import';
    ImportLink.ElementFont := efCSS;
    ImportLink.HeightStyle := ssAuto;
    ImportLink.HeightPercent := 100.000000000000000000;
    ImportLink.WidthPercent := 100.000000000000000000;
    SetEvent(ImportLink, Self, 'OnClick', 'ImportLinkClick');
    ManualEntryLink.SetParentComponent(Self);
    ManualEntryLink.Name := 'ManualEntryLink';
    ManualEntryLink.Left := 24;
    ManualEntryLink.Top := 232;
    ManualEntryLink.Width := 70;
    ManualEntryLink.Height := 15;
    ManualEntryLink.Caption := 'Manual Entry';
    ManualEntryLink.HeightPercent := 100.000000000000000000;
    ManualEntryLink.WidthPercent := 100.000000000000000000;
    SetEvent(ManualEntryLink, Self, 'OnClick', 'ManualEntryLinkClick');
    UnclaimedDonationsLink.SetParentComponent(Self);
    UnclaimedDonationsLink.Name := 'UnclaimedDonationsLink';
    UnclaimedDonationsLink.Left := 28;
    UnclaimedDonationsLink.Top := 337;
    UnclaimedDonationsLink.Width := 114;
    UnclaimedDonationsLink.Height := 15;
    UnclaimedDonationsLink.Cursor := crHandPoint;
    UnclaimedDonationsLink.Caption := 'Unclaimed Donations';
    UnclaimedDonationsLink.ElementFont := efCSS;
    UnclaimedDonationsLink.HeightStyle := ssAuto;
    UnclaimedDonationsLink.HeightPercent := 100.000000000000000000;
    UnclaimedDonationsLink.WidthStyle := ssAuto;
    UnclaimedDonationsLink.WidthPercent := 100.000000000000000000;
    SetEvent(UnclaimedDonationsLink, Self, 'OnClick', 'UnclaimedDonationsLinkClick');
    MakeAClaimLink.SetParentComponent(Self);
    MakeAClaimLink.Name := 'MakeAClaimLink';
    MakeAClaimLink.Left := 28;
    MakeAClaimLink.Top := 369;
    MakeAClaimLink.Width := 72;
    MakeAClaimLink.Height := 15;
    MakeAClaimLink.Cursor := crHandPoint;
    MakeAClaimLink.Caption := 'Make a Claim';
    MakeAClaimLink.ElementFont := efCSS;
    MakeAClaimLink.HeightStyle := ssAuto;
    MakeAClaimLink.HeightPercent := 100.000000000000000000;
    MakeAClaimLink.WidthStyle := ssAuto;
    MakeAClaimLink.WidthPercent := 100.000000000000000000;
    SetEvent(MakeAClaimLink, Self, 'OnClick', 'MakeAClaimLinkClick');
    ManualDonationLink.SetParentComponent(Self);
    ManualDonationLink.Name := 'ManualDonationLink';
    ManualDonationLink.Left := 30;
    ManualDonationLink.Top := 408;
    ManualDonationLink.Width := 111;
    ManualDonationLink.Height := 15;
    ManualDonationLink.Caption := 'ManualDonationLink';
    ManualDonationLink.ElementFont := efCSS;
    ManualDonationLink.HeightStyle := ssAuto;
    ManualDonationLink.HeightPercent := 100.000000000000000000;
    ManualDonationLink.WidthPercent := 100.000000000000000000;
    SetEvent(ManualDonationLink, Self, 'OnClick', 'ManualDonationLinkClick');
    SysConfigLink.SetParentComponent(Self);
    SysConfigLink.Name := 'SysConfigLink';
    SysConfigLink.Left := 23;
    SysConfigLink.Top := 496;
    SysConfigLink.Width := 77;
    SysConfigLink.Height := 15;
    SysConfigLink.Cursor := crHandPoint;
    SysConfigLink.Caption := 'System Config';
    SysConfigLink.ElementFont := efCSS;
    SysConfigLink.HeightStyle := ssAuto;
    SysConfigLink.HeightPercent := 100.000000000000000000;
    SysConfigLink.WidthPercent := 100.000000000000000000;
    SetEvent(SysConfigLink, Self, 'OnClick', 'SysConfigLinkClick');
    OtherIncomeLink.SetParentComponent(Self);
    OtherIncomeLink.Name := 'OtherIncomeLink';
    OtherIncomeLink.Left := 24;
    OtherIncomeLink.Top := 576;
    OtherIncomeLink.Width := 73;
    OtherIncomeLink.Height := 15;
    OtherIncomeLink.Caption := 'Other Income';
    OtherIncomeLink.HeightPercent := 100.000000000000000000;
    OtherIncomeLink.WidthPercent := 100.000000000000000000;
    SetEvent(OtherIncomeLink, Self, 'OnClick', 'OtherIncomeLinkClick');
    AggregatedDonationsLink.SetParentComponent(Self);
    AggregatedDonationsLink.Name := 'AggregatedDonationsLink';
    AggregatedDonationsLink.Left := 24;
    AggregatedDonationsLink.Top := 597;
    AggregatedDonationsLink.Width := 119;
    AggregatedDonationsLink.Height := 15;
    AggregatedDonationsLink.Caption := 'Aggregated Donations';
    AggregatedDonationsLink.HeightPercent := 100.000000000000000000;
    AggregatedDonationsLink.WidthPercent := 100.000000000000000000;
    SetEvent(AggregatedDonationsLink, Self, 'OnClick', 'AggregatedDonationsLinkClick');
    GASDSLink.SetParentComponent(Self);
    GASDSLink.Name := 'GASDSLink';
    GASDSLink.Left := 24;
    GASDSLink.Top := 618;
    GASDSLink.Width := 36;
    GASDSLink.Height := 15;
    GASDSLink.Caption := 'GASDS';
    GASDSLink.HeightPercent := 100.000000000000000000;
    GASDSLink.WidthPercent := 100.000000000000000000;
    SetEvent(GASDSLink, Self, 'OnClick', 'GASDSLinkClick');
    GASDSAdjustmentsLink.SetParentComponent(Self);
    GASDSAdjustmentsLink.Name := 'GASDSAdjustmentsLink';
    GASDSAdjustmentsLink.Left := 24;
    GASDSAdjustmentsLink.Top := 639;
    GASDSAdjustmentsLink.Width := 106;
    GASDSAdjustmentsLink.Height := 15;
    GASDSAdjustmentsLink.Caption := 'GASDS Adjustments';
    GASDSAdjustmentsLink.HeightPercent := 100.000000000000000000;
    GASDSAdjustmentsLink.WidthPercent := 100.000000000000000000;
    SetEvent(GASDSAdjustmentsLink, Self, 'OnClick', 'GASDSAdjustmentsLinkClick');
    AdjustmentsLink.SetParentComponent(Self);
    AdjustmentsLink.Name := 'AdjustmentsLink';
    AdjustmentsLink.Left := 26;
    AdjustmentsLink.Top := 664;
    AdjustmentsLink.Width := 67;
    AdjustmentsLink.Height := 15;
    AdjustmentsLink.Caption := 'Adjustments';
    AdjustmentsLink.HeightPercent := 100.000000000000000000;
    AdjustmentsLink.WidthPercent := 100.000000000000000000;
    SetEvent(AdjustmentsLink, Self, 'OnClick', 'AdjustmentsLinkClick');
    CommunityBuildingsLink.SetParentComponent(Self);
    CommunityBuildingsLink.Name := 'CommunityBuildingsLink';
    CommunityBuildingsLink.Left := 180;
    CommunityBuildingsLink.Top := 655;
    CommunityBuildingsLink.Width := 116;
    CommunityBuildingsLink.Height := 15;
    CommunityBuildingsLink.Caption := 'Community Buildings';
    CommunityBuildingsLink.ElementPosition := epIgnore;
    CommunityBuildingsLink.HeightStyle := ssAuto;
    CommunityBuildingsLink.HeightPercent := 100.000000000000000000;
    CommunityBuildingsLink.WidthStyle := ssAuto;
    CommunityBuildingsLink.WidthPercent := 100.000000000000000000;
    SetEvent(CommunityBuildingsLink, Self, 'OnClick', 'CommunityBuildingsLinkClick');
    OtherClaimsCommunityBuildings.SetParentComponent(Self);
    OtherClaimsCommunityBuildings.Name := 'OtherClaimsCommunityBuildings';
    OtherClaimsCommunityBuildings.Left := 322;
    OtherClaimsCommunityBuildings.Top := 655;
    OtherClaimsCommunityBuildings.Width := 191;
    OtherClaimsCommunityBuildings.Height := 15;
    OtherClaimsCommunityBuildings.Caption := 'Other Claims: Community Buildings';
    OtherClaimsCommunityBuildings.ElementPosition := epIgnore;
    OtherClaimsCommunityBuildings.HeightStyle := ssAuto;
    OtherClaimsCommunityBuildings.HeightPercent := 100.000000000000000000;
    OtherClaimsCommunityBuildings.WidthStyle := ssAuto;
    OtherClaimsCommunityBuildings.WidthPercent := 100.000000000000000000;
    SetEvent(OtherClaimsCommunityBuildings, Self, 'OnClick', 'OtherClaimsCommunityBuildingsClick');
    ConnectedCharitiesLink.SetParentComponent(Self);
    ConnectedCharitiesLink.Name := 'ConnectedCharitiesLink';
    ConnectedCharitiesLink.Left := 202;
    ConnectedCharitiesLink.Top := 496;
    ConnectedCharitiesLink.Width := 107;
    ConnectedCharitiesLink.Height := 15;
    ConnectedCharitiesLink.Caption := 'Connected Charities';
    ConnectedCharitiesLink.ElementPosition := epIgnore;
    ConnectedCharitiesLink.HeightStyle := ssAuto;
    ConnectedCharitiesLink.HeightPercent := 100.000000000000000000;
    ConnectedCharitiesLink.WidthStyle := ssAuto;
    ConnectedCharitiesLink.WidthPercent := 100.000000000000000000;
    SetEvent(ConnectedCharitiesLink, Self, 'OnClick', 'ConnectedCharitiesLinkClick');
    OrganisationLink.SetParentComponent(Self);
    OrganisationLink.Name := 'OrganisationLink';
    OrganisationLink.Left := 21;
    OrganisationLink.Top := 443;
    OrganisationLink.Width := 68;
    OrganisationLink.Height := 15;
    OrganisationLink.Caption := 'Organisation';
    OrganisationLink.HeightPercent := 100.000000000000000000;
    OrganisationLink.WidthPercent := 100.000000000000000000;
    SetEvent(OrganisationLink, Self, 'OnClick', 'OrganisationLinkClick');
    TenantNameLabel.SetParentComponent(Self);
    TenantNameLabel.Name := 'TenantNameLabel';
    TenantNameLabel.Left := 292;
    TenantNameLabel.Top := 45;
    TenantNameLabel.Width := 96;
    TenantNameLabel.Height := 15;
    TenantNameLabel.Caption := 'TenantNameLabel';
    TenantNameLabel.ElementPosition := epIgnore;
    TenantNameLabel.HeightStyle := ssAuto;
    TenantNameLabel.HeightPercent := 100.000000000000000000;
    TenantNameLabel.HTMLType := tDIV;
    TenantNameLabel.WidthStyle := ssAuto;
    TenantNameLabel.WidthPercent := 100.000000000000000000;
    UsersAdminLink.SetParentComponent(Self);
    UsersAdminLink.Name := 'UsersAdminLink';
    UsersAdminLink.Left := 21;
    UsersAdminLink.Top := 464;
    UsersAdminLink.Width := 97;
    UsersAdminLink.Height := 15;
    UsersAdminLink.Caption := 'User Management';
    UsersAdminLink.HeightPercent := 100.000000000000000000;
    UsersAdminLink.WidthPercent := 100.000000000000000000;
    SetEvent(UsersAdminLink, Self, 'OnClick', 'UsersAdminLinkClick');
    MainMessageDlg.SetParentComponent(Self);
    MainMessageDlg.Name := 'MainMessageDlg';
    MainMessageDlg.Left := 306;
    MainMessageDlg.Top := 272;
    MainMessageDlg.Width := 24;
    MainMessageDlg.Height := 24;
    MainMessageDlg.Buttons := [];
    MainMessageDlg.Opacity := 0.200000000000000000;
    MainMessageDlg.ElementButtonClassName := 'btn';
    MainMessageDlg.ElementDialogClassName := 'SMXDialogBox shadow-lg mb-5 bg-white rounded';
    MainMessageDlg.ElementTitleClassName := 'text-body';
    MainMessageDlg.ElementContentClassName := 'text-body';
    userInitials.SetParentComponent(Self);
    userInitials.Name := 'userInitials';
    userInitials.Left := 456;
    userInitials.Top := 20;
    userInitials.Width := 100;
    userInitials.Height := 40;
    userInitials.HeightStyle := ssAuto;
    userInitials.WidthStyle := ssAuto;
    userInitials.ChildOrder := 28;
    userInitials.ElementPosition := epIgnore;
    userInitials.Role := '';
    MainToast.SetParentComponent(Self);
    MainToast.Name := 'MainToast';
    MainToast.AutoHideDelay := 3000;
    MainToast.Position := tpBottomRight;
    MainToast.Left := 300;
    MainToast.Top := 184;
    DashboardTimer.SetParentComponent(Self);
    DashboardTimer.Name := 'DashboardTimer';
    DashboardTimer.Enabled := False;
    SetEvent(DashboardTimer, Self, 'OnTimer', 'DashboardTimerTimer');
    DashboardTimer.Left := 704;
    DashboardTimer.Top := 40;
    AgentTimer.SetParentComponent(Self);
    AgentTimer.Name := 'AgentTimer';
    AgentTimer.Enabled := False;
    AgentTimer.Interval := 500;
    SetEvent(AgentTimer, Self, 'OnTimer', 'AgentTimerTimer');
    AgentTimer.Left := 704;
    AgentTimer.Top := 120;
    JobListRefreshTimer.SetParentComponent(Self);
    JobListRefreshTimer.Name := 'JobListRefreshTimer';
    JobListRefreshTimer.Enabled := False;
    SetEvent(JobListRefreshTimer, Self, 'OnTimer', 'JobListRefreshTimerTimer');
    JobListRefreshTimer.Left := 704;
    JobListRefreshTimer.Top := 280;
    ReloadJobsTimer.SetParentComponent(Self);
    ReloadJobsTimer.Name := 'ReloadJobsTimer';
    ReloadJobsTimer.Enabled := False;
    SetEvent(ReloadJobsTimer, Self, 'OnTimer', 'ReloadJobsTimerTimer');
    ReloadJobsTimer.Left := 704;
    ReloadJobsTimer.Top := 232;
  finally
    DashboardLabel.AfterLoadDFMValues;
    ExceptionTestLink.AfterLoadDFMValues;
    SupportLink.AfterLoadDFMValues;
    LogoutLabel.AfterLoadDFMValues;
    LoggedInUserLabel.AfterLoadDFMValues;
    GiftAidLink.AfterLoadDFMValues;
    NewDonorsLabel.AfterLoadDFMValues;
    DonorAdminLabel.AfterLoadDFMValues;
    ImportLink.AfterLoadDFMValues;
    ManualEntryLink.AfterLoadDFMValues;
    UnclaimedDonationsLink.AfterLoadDFMValues;
    MakeAClaimLink.AfterLoadDFMValues;
    ManualDonationLink.AfterLoadDFMValues;
    SysConfigLink.AfterLoadDFMValues;
    OtherIncomeLink.AfterLoadDFMValues;
    AggregatedDonationsLink.AfterLoadDFMValues;
    GASDSLink.AfterLoadDFMValues;
    GASDSAdjustmentsLink.AfterLoadDFMValues;
    AdjustmentsLink.AfterLoadDFMValues;
    CommunityBuildingsLink.AfterLoadDFMValues;
    OtherClaimsCommunityBuildings.AfterLoadDFMValues;
    ConnectedCharitiesLink.AfterLoadDFMValues;
    OrganisationLink.AfterLoadDFMValues;
    TenantNameLabel.AfterLoadDFMValues;
    UsersAdminLink.AfterLoadDFMValues;
    MainMessageDlg.AfterLoadDFMValues;
    userInitials.AfterLoadDFMValues;
    MainToast.AfterLoadDFMValues;
    DashboardTimer.AfterLoadDFMValues;
    AgentTimer.AfterLoadDFMValues;
    JobListRefreshTimer.AfterLoadDFMValues;
    ReloadJobsTimer.AfterLoadDFMValues;
  end;
end;

end.
