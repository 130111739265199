unit DonorForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  Vcl.Imaging.GIFImg,
  WEBLib.DropDown,
  WEBLib.Country,
  XData.Web.Client;

type
  TDonorEditForm = class(TBaseEditForm)
    edFirstName: TDBEdit;
    edLastName: TDBEdit;
    edAdd1: TDBEdit;
    edAdd2: TDBEdit;
    edAdd3: TDBEdit;
    edCity: TDBEdit;
    edPostCode: TDBEdit;
    edEmail: TDBEdit;
    edMobile: TDBEdit;
    edTelephone: TDBEdit;
    DonorIdLabel: TDBLabel;
    ClaimStatus: TDBLookupComboBox;
    Comments: TDBEdit;
    ContactByPhone: TDBCheckBox;
    ContactByEmail: TDBCheckBox;
    ContactByText: TDBCheckBox;
    ContactByPost: TDBCheckBox;
    ExternalIdLabel: TDBLabel;
    TitleEdit: TDBEdit;
    ExternalIdEdit: TDBEdit;
    edCounty: TDBEdit;
    CountryList: TCountryComboBox;
    procedure ClaimStatusChange(Sender: TObject);
    procedure ClaimStatusExit(Sender: TObject);
    procedure CountryListChange(Sender: TObject);
    procedure CountryListExit(Sender: TObject);
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure edAdd1Exit(Sender: TObject);
    procedure edFirstNameExit(Sender: TObject);
    procedure edLastNameExit(Sender: TObject);
    procedure edPostCodeExit(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure EditChange(Sender: TObject);
  private
    { Private declarations }
    FPostCodeValid: boolean;
    FCountryValid: boolean;
    FClaimStatusValid: boolean;
    FForeignCountry: boolean;
    procedure ExternalIdLabelClick(Sender: TObject);

    [Async]
    procedure ExternalIdClick; async;
    procedure AfterSetDataset; override;
    procedure ValidatePostCode;
    function AnyContactAllowed: Boolean;
    [Async]
    procedure GetTitleList; async;
    procedure CheckValidation;
    procedure SetPostCodeValidation(bValid: boolean);

  protected
    function DisplayName: string;
    function SaveTitle: string; override;
    function SaveDescription: string; override;
    function GetRequiredEditLevel: string; override;
    procedure BeforePostRecord; override;
    procedure SetCountryCode; virtual;
    procedure CheckClaimStatusIsOther;
    procedure SetCountryToCountryCode(ACountryCode: string);
  public
    { Public declarations }
    procedure SetLookUpValues(const AName: string; Value: TLookupValues); override;
    property PostCodeValid: boolean read FPostCodeValid write SetPostCodeValidation;

  protected procedure LoadDFMValues; override; end;

implementation

uses
  DonorList,
  SMX.Shared,
  Auth.Service,
  SMX.CRM.Utils,
  MainDataModule,
  SMX.Web.Document.Utils;

{$R *.dfm}

procedure TDonorEditForm.AfterSetDataset;
var
  lCountryCode: string;
  lCountry: string;
  lClaimStatus: string;
  lClaimStatusIdx: integer;
  lDS: TDataSetState;
begin
  inherited;

  if AuthService.IsAdministrator then
  begin
    if Dataset.FieldByName('ExternalId').AsString <> '' then
    begin
      ExternalIdLabel.OnClick := ExternalIdLabelClick;
      //ExternalIdLabel.ElementClassName := 'clickable';
      ExternalIdLabel.Cursor := crHandPoint;
    end;
  end;

  if Dataset.FieldByName('CountryCode').AsString <> '' then
  begin
    lCountryCode := Dataset.FieldByName('CountryCode').AsString;
    SetCountryToCountryCode(lCountryCode);
  end
  else
  begin
    if DataSource.DataSet.State in [dsInsert] then
    begin
      SetCountryToCountryCode('GB');
      FForeignCountry := False;
      FCountryValid := True;
    end;
  end;

  if Dataset.FieldByName('ClaimStatus').AsString <> '' then
  begin
    lClaimStatus := Dataset.FieldByName('ClaimStatus').AsString;
    ClaimStatus.Value := lClaimStatus;
  end;

  //if Dataset.FieldByName('Title').AsString <> '' then
  //begin
  //  Title.Text := Dataset.FieldByName('Title').AsString;
  //end;
  //Add this later GetTitleList;

  if not (DataSource.DataSet.State in [dsInsert]) then
  begin
    FPostCodeValid := True;
    FCountryValid := True;
    FClaimStatusValid := True;
  end
  else
  begin
    if DataSource.DataSet.State = dsInsert then
    begin
      Dataset.FieldByName('ClaimStatus').AsString := 'Ok';
      ClaimStatus.ItemIndex := 0;
      FClaimStatusValid := True;
    end;
  end;
  CheckClaimStatusIsOther;
  CheckValidation;
end;

function TDonorEditForm.AnyContactAllowed: Boolean;
begin
  result := ContactByEmail.Checked or ContactByPhone.Checked or ContactByPost.Checked or ContactByText.Checked;
end;

procedure TDonorEditForm.BeforePostRecord;
var
  lTitle: string;
  lDateSigned: TDate;
  lCountry: string;
  lCountryCode: string;

begin
  inherited;

  lCountry := CountryList.Text;
  lCountryCode := GetCountryCode(lCountry);

  Dataset.FieldByName('ContactByPhone').AsBoolean := ContactByPhone.Checked;
  Dataset.FieldByName('ContactByPost').AsBoolean := ContactByPost.Checked;
  Dataset.FieldByName('ContactByText').AsBoolean := ContactByText.Checked;
  Dataset.FieldByName('ContactByEmail').AsBoolean := ContactByEmail.Checked;
  Dataset.FieldByName('CountryCode').AsString := lCountryCode;
end;

procedure TDonorEditForm.CheckClaimStatusIsOther;
begin
  Comments.Visible := (ClaimStatus.Value = 'Other');
end;

procedure TDonorEditForm.ClaimStatusChange(Sender: TObject);
begin
  CheckClaimStatusIsOther;
end;

procedure TDonorEditForm.CountryListChange(Sender: TObject);
begin
  inherited;
  SetCountryCode;
end;

procedure TDonorEditForm.CountryListExit(Sender: TObject);
begin
  inherited;
  SetCountryCode;
end;

procedure TDonorEditForm.DataSourceDataChange(Sender: TObject; Field: TField);
begin
  inherited;
  if Field = nil then
  begin
    FormCaption.Caption := DisplayName;
  end;
end;

function TDonorEditForm.DisplayName: string;
begin
  result := Datasource.Dataset.FieldByName('FirstName').AsString + ' ' + Datasource.Dataset.FieldByName
    ('LastName').AsString;
end;


procedure TDonorEditForm.edPostCodeExit(Sender: TObject);
begin
  ValidatePostCode;
end;

procedure TDonorEditForm.ExternalIdClick;
begin

end;

procedure TDonorEditForm.ExternalIdLabelClick(Sender: TObject);
begin
  ExternalIdClick;
end;

function TDonorEditForm.GetRequiredEditLevel: string;
begin
  result := SCOPE_ANY_ADMIN;
end;

function TDonorEditForm.SaveDescription: string;
begin
  case FLastActionState of
    dsEdit:
      result := 'Your changes for ' + DisplayName + ' have been saved';
    dsInsert:
      result := DisplayName + ' has been added.';
  else
    result := DisplayName;
  end;
end;

function TDonorEditForm.SaveTitle: string;
begin
  case FLastActionState of
    dsEdit:
      result := 'Changes for ' + DisplayName;
    dsInsert:
      result := DisplayName + ' Added';
  else
    result := DisplayName;
  end;
end;

procedure TDonorEditForm.SetLookUpValues(const AName: string; Value: TLookupValues);
// var
// I: Integer;
begin
  // for I := 1 to Value.Count - 1 do
  // begin
  // ShopRef.LookUpValues.AddPair(TLookupValueItem(Value.Items[I]).Value, TLookupValueItem(Value.Items[I]).DisplayText);
  // end;
end;

procedure TDonorEditForm.SetPostCodeValidation(bValid: boolean);
begin
  FPostCodeValid := bValid;
  CheckValidation;
end;

procedure TDonorEditForm.EditChange(Sender: TObject);
begin
  CheckValidation;
end;

procedure TDonorEditForm.ValidatePostCode;
begin
  if not FForeignCountry then
  begin
    if (Trim(edPostCode.Text) = '') then
    begin
      TDocUtils.setControlValidity('PostCode', vsInvalid);
      PostCodeValid := False;
      Exit;
    end;

    edPostCode.Text := TCRMUtils.FormatPostCode(edPostCode.Text);
    if TCRMUtils.IsPostCode(edPostCode.text) <> pcFull then
    begin
      TDocUtils.setControlValidity('PostCode', vsInvalid);
      PostCodeValid := False;
      Exit;
    end;
  end;
  TDocUtils.setControlValidity('PostCode', vsValid);
  PostCodeValid := True;
end;

procedure TDonorEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  ClaimStatus.LookupValues.AddPair('OK', 'OK');
  ClaimStatus.LookupValues.AddPair('AwaitingPaperwork', 'Awaiting Paperwork');
  ClaimStatus.LookupValues.AddPair('Deceased', 'Deceased');
  ClaimStatus.LookupValues.AddPair('DonorDetailsIncomplete', 'Donor Details Incomplete');
  ClaimStatus.LookupValues.AddPair('NoLongerPaysTax', 'No Longer Pays Tax');
  ClaimStatus.LookupValues.AddPair('NoValidAddressGiven', 'No ValidAddress Given');
  ClaimStatus.LookupValues.AddPair('RequestedRefund', 'Requested Refund');
  ClaimStatus.LookupValues.AddPair('Other', 'Other - please add comment');
  TrapEnterPressSetUp;

end;

procedure TDonorEditForm.WebFormShow(Sender: TObject);
begin
  TitleEdit.SetFocus;
  CheckClaimStatusIsOther;
  FPostCodeValid := False;
  FClaimStatusValid := False;
  SaveButton.Enabled := False;
end;

procedure TDonorEditForm.GetTitleList;
//var
  //lRetval: TXDataClientResponse;
  //lResult: JS.TJSObject;
  //lTitleList: TStringList;
  //lTitle: string;
begin
  // We might add this back later
  //lTitleList := TStringList.Create;
  //lRetval := await(TXDataClientResponse,
  //  MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.GetTitleTypes', []));
  //lResult := lRetval.ResultAsObject;

  //FTitleListStr := Js.toString(lResult['value']);
  //Title.Items.Text := FTitleListStr;
end;

procedure TDonorEditForm.SetCountryCode;
var
  lCountry: string;
  lCountryCode: string;

begin
  lCountry := CountryList.Text;
  lCountryCode := GetCountryCode(lCountry);

  if not (DataSet.State in dsEditModes) then
    Dataset.Edit;

  if lCountryCode = 'GB' then
    FForeignCountry := False
  else
    FForeignCountry := True;

  Dataset.FieldByName('CountryCode').AsString := lCountryCode;
  FCountryValid := true;

  if CountryList.ItemIndex > -1 then
    TDocUtils.setControlValidity('CountryList', vsValid)
  else
    TDocUtils.setControlValidity('CountryList', vsInvalid);

  ValidatePostCode;
end;

procedure TDonorEditForm.SetCountryToCountryCode(ACountryCode: string);
var
  lCountry: string;
begin
  if ACountryCode <> '' then
  begin
    lCountry := GetCountryByCode(ACountryCode);
    CountryList.Text := lCountry;
  end;
end;

procedure TDonorEditForm.edFirstNameExit(Sender: TObject);
begin
  if edFirstName.Text <> '' then
    TDocUtils.setControlValidity('FirstName', vsValid)
  else
    TDocUtils.setControlValidity('FirstName', vsInvalid);
  CheckValidation;
end;

procedure TDonorEditForm.edLastNameExit(Sender: TObject);
begin
  if edLastName.Text <> '' then
    TDocUtils.setControlValidity('LastName', vsValid)
  else
    TDocUtils.setControlValidity('LastName', vsInvalid);
  CheckValidation;
end;

procedure TDonorEditForm.edAdd1Exit(Sender: TObject);
begin
  if edAdd1.Text <> '' then
    TDocUtils.setControlValidity('Add1', vsValid)
  else
    TDocUtils.setControlValidity('Add1', vsInvalid);
  CheckValidation;
end;

procedure TDonorEditForm.ClaimStatusExit(Sender: TObject);
begin
  if ClaimStatus.ItemIndex > -1 then
  begin
    TDocUtils.setControlValidity('ClaimStatus', vsValid);
    FClaimStatusValid := True;
  end
  else
  begin
    TDocUtils.setControlValidity('ClaimStatus', vsInvalid);
    FClaimStatusValid := False;
  end;
  CheckValidation;
end;

procedure TDonorEditForm.CheckValidation;
begin
  if FPostCodeValid and FCountryValid and FClaimStatusValid and
    (edFirstName.Text <> '') and (edLastName.Text <> '') and (edAdd1.Text <> '') then
    SaveButton.Enabled := True
  else
    SaveButton.Enabled := False;
end;




procedure TDonorEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DonorIdLabel := TDBLabel.Create('DonorIdLabel');
  ExternalIdLabel := TDBLabel.Create('ExternalIdLabel');
  edFirstName := TDBEdit.Create('FirstName');
  edLastName := TDBEdit.Create('LastName');
  edAdd1 := TDBEdit.Create('Add1');
  edAdd2 := TDBEdit.Create('Add2');
  edAdd3 := TDBEdit.Create('Add3');
  edCity := TDBEdit.Create('City');
  edPostCode := TDBEdit.Create('PostCode');
  edEmail := TDBEdit.Create('Email');
  edMobile := TDBEdit.Create('Mobile');
  edTelephone := TDBEdit.Create('Phone');
  ClaimStatus := TDBLookupComboBox.Create('ClaimStatus');
  Comments := TDBEdit.Create('Comments');
  ContactByPhone := TDBCheckBox.Create('ContactByPhone');
  ContactByEmail := TDBCheckBox.Create('ContactByEmail');
  ContactByText := TDBCheckBox.Create('ContactByText');
  ContactByPost := TDBCheckBox.Create('ContactByPost');
  TitleEdit := TDBEdit.Create('Title');
  ExternalIdEdit := TDBEdit.Create('ExternalIdEdit');
  edCounty := TDBEdit.Create('CountyEdit');
  CountryList := TCountryComboBox.Create('CountryList');

  DonorIdLabel.BeforeLoadDFMValues;
  ExternalIdLabel.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  edFirstName.BeforeLoadDFMValues;
  edLastName.BeforeLoadDFMValues;
  edAdd1.BeforeLoadDFMValues;
  edAdd2.BeforeLoadDFMValues;
  edAdd3.BeforeLoadDFMValues;
  edCity.BeforeLoadDFMValues;
  edPostCode.BeforeLoadDFMValues;
  edEmail.BeforeLoadDFMValues;
  edMobile.BeforeLoadDFMValues;
  edTelephone.BeforeLoadDFMValues;
  ClaimStatus.BeforeLoadDFMValues;
  Comments.BeforeLoadDFMValues;
  ContactByPhone.BeforeLoadDFMValues;
  ContactByEmail.BeforeLoadDFMValues;
  ContactByText.BeforeLoadDFMValues;
  ContactByPost.BeforeLoadDFMValues;
  DataState.BeforeLoadDFMValues;
  TitleEdit.BeforeLoadDFMValues;
  ExternalIdEdit.BeforeLoadDFMValues;
  edCounty.BeforeLoadDFMValues;
  CountryList.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  try
    Width := 900;
    Height := 508;
    Caption := 'MainData.DummySource';
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DonorIdLabel.SetParentComponent(Self);
    DonorIdLabel.Name := 'DonorIdLabel';
    DonorIdLabel.Left := 232;
    DonorIdLabel.Top := 27;
    DonorIdLabel.Width := 71;
    DonorIdLabel.Height := 15;
    DonorIdLabel.Caption := 'DonorIdLabel';
    DonorIdLabel.ElementFont := efCSS;
    DonorIdLabel.HeightStyle := ssAuto;
    DonorIdLabel.HeightPercent := 100.000000000000000000;
    DonorIdLabel.WidthPercent := 100.000000000000000000;
    DonorIdLabel.DataField := 'Id';
    DonorIdLabel.DataSource := DataSource;
    ExternalIdLabel.SetParentComponent(Self);
    ExternalIdLabel.Name := 'ExternalIdLabel';
    ExternalIdLabel.Left := 232;
    ExternalIdLabel.Top := 46;
    ExternalIdLabel.Width := 79;
    ExternalIdLabel.Height := 15;
    ExternalIdLabel.Caption := 'ExternalIdLabel';
    ExternalIdLabel.ElementFont := efCSS;
    ExternalIdLabel.HeightStyle := ssAuto;
    ExternalIdLabel.HeightPercent := 100.000000000000000000;
    ExternalIdLabel.WidthStyle := ssAuto;
    ExternalIdLabel.WidthPercent := 100.000000000000000000;
    ExternalIdLabel.DataField := 'ExternalId';
    ExternalIdLabel.DataSource := DataSource;
    CancelButton.Left := 192;
    CancelButton.Top := 398;
    CancelButton.TabOrder := 22;
    SaveButton.Left := 24;
    SaveButton.Top := 398;
    SaveButton.TabOrder := 21;
    edFirstName.SetParentComponent(Self);
    edFirstName.Name := 'edFirstName';
    edFirstName.Left := 24;
    edFirstName.Top := 161;
    edFirstName.Width := 200;
    edFirstName.Height := 21;
    edFirstName.ChildOrder := 9;
    edFirstName.ElementClassName := 'form-control';
    edFirstName.ElementFont := efCSS;
    edFirstName.ElementPosition := epIgnore;
    edFirstName.HeightStyle := ssAuto;
    edFirstName.HeightPercent := 100.000000000000000000;
    edFirstName.TabOrder := 2;
    edFirstName.Text := 'edFirstName';
    edFirstName.WidthStyle := ssAuto;
    edFirstName.WidthPercent := 100.000000000000000000;
    SetEvent(edFirstName, Self, 'OnChange', 'EditChange');
    SetEvent(edFirstName, Self, 'OnExit', 'edFirstNameExit');
    edFirstName.DataField := 'FirstName';
    edFirstName.DataSource := DataSource;
    edLastName.SetParentComponent(Self);
    edLastName.Name := 'edLastName';
    edLastName.Left := 24;
    edLastName.Top := 188;
    edLastName.Width := 200;
    edLastName.Height := 21;
    edLastName.ChildOrder := 10;
    edLastName.ElementClassName := 'form-control';
    edLastName.ElementFont := efCSS;
    edLastName.ElementPosition := epIgnore;
    edLastName.HeightStyle := ssAuto;
    edLastName.HeightPercent := 100.000000000000000000;
    edLastName.TabOrder := 3;
    edLastName.Text := 'edLastName';
    edLastName.WidthStyle := ssAuto;
    edLastName.WidthPercent := 100.000000000000000000;
    SetEvent(edLastName, Self, 'OnChange', 'EditChange');
    SetEvent(edLastName, Self, 'OnExit', 'edLastNameExit');
    edLastName.DataField := 'LastName';
    edLastName.DataSource := DataSource;
    edAdd1.SetParentComponent(Self);
    edAdd1.Name := 'edAdd1';
    edAdd1.Left := 253;
    edAdd1.Top := 91;
    edAdd1.Width := 200;
    edAdd1.Height := 21;
    edAdd1.ChildOrder := 11;
    edAdd1.ElementClassName := 'form-control';
    edAdd1.ElementFont := efCSS;
    edAdd1.ElementPosition := epIgnore;
    edAdd1.HeightStyle := ssAuto;
    edAdd1.HeightPercent := 100.000000000000000000;
    edAdd1.TabOrder := 4;
    edAdd1.Text := 'edAdd1';
    edAdd1.WidthStyle := ssAuto;
    edAdd1.WidthPercent := 100.000000000000000000;
    SetEvent(edAdd1, Self, 'OnChange', 'EditChange');
    SetEvent(edAdd1, Self, 'OnExit', 'edAdd1Exit');
    edAdd1.DataField := 'Add1';
    edAdd1.DataSource := DataSource;
    edAdd2.SetParentComponent(Self);
    edAdd2.Name := 'edAdd2';
    edAdd2.Left := 253;
    edAdd2.Top := 118;
    edAdd2.Width := 200;
    edAdd2.Height := 21;
    edAdd2.ChildOrder := 12;
    edAdd2.ElementClassName := 'form-control';
    edAdd2.ElementFont := efCSS;
    edAdd2.ElementPosition := epIgnore;
    edAdd2.HeightStyle := ssAuto;
    edAdd2.HeightPercent := 100.000000000000000000;
    edAdd2.TabOrder := 5;
    edAdd2.Text := 'edAdd2';
    edAdd2.WidthStyle := ssAuto;
    edAdd2.WidthPercent := 100.000000000000000000;
    SetEvent(edAdd2, Self, 'OnChange', 'EditChange');
    edAdd2.DataField := 'Add2';
    edAdd2.DataSource := DataSource;
    edAdd3.SetParentComponent(Self);
    edAdd3.Name := 'edAdd3';
    edAdd3.Left := 253;
    edAdd3.Top := 145;
    edAdd3.Width := 200;
    edAdd3.Height := 21;
    edAdd3.ChildOrder := 13;
    edAdd3.ElementClassName := 'form-control';
    edAdd3.ElementFont := efCSS;
    edAdd3.ElementPosition := epIgnore;
    edAdd3.HeightStyle := ssAuto;
    edAdd3.HeightPercent := 100.000000000000000000;
    edAdd3.TabOrder := 6;
    edAdd3.Text := 'edAdd3';
    edAdd3.WidthStyle := ssAuto;
    edAdd3.WidthPercent := 100.000000000000000000;
    SetEvent(edAdd3, Self, 'OnChange', 'EditChange');
    edAdd3.DataField := 'Add3';
    edAdd3.DataSource := DataSource;
    edCity.SetParentComponent(Self);
    edCity.Name := 'edCity';
    edCity.Left := 253;
    edCity.Top := 171;
    edCity.Width := 200;
    edCity.Height := 21;
    edCity.ChildOrder := 14;
    edCity.ElementClassName := 'form-control';
    edCity.ElementFont := efCSS;
    edCity.ElementPosition := epIgnore;
    edCity.HeightStyle := ssAuto;
    edCity.HeightPercent := 100.000000000000000000;
    edCity.TabOrder := 7;
    edCity.Text := 'edCity';
    edCity.WidthStyle := ssAuto;
    edCity.WidthPercent := 100.000000000000000000;
    SetEvent(edCity, Self, 'OnChange', 'EditChange');
    edCity.DataField := 'City';
    edCity.DataSource := DataSource;
    edPostCode.SetParentComponent(Self);
    edPostCode.Name := 'edPostCode';
    edPostCode.Left := 253;
    edPostCode.Top := 198;
    edPostCode.Width := 200;
    edPostCode.Height := 21;
    edPostCode.CharCase := wecUpperCase;
    edPostCode.ChildOrder := 15;
    edPostCode.ElementClassName := 'form-control';
    edPostCode.ElementFont := efCSS;
    edPostCode.ElementPosition := epIgnore;
    edPostCode.HeightStyle := ssAuto;
    edPostCode.HeightPercent := 100.000000000000000000;
    edPostCode.TabOrder := 8;
    edPostCode.Text := 'edPostCode';
    edPostCode.WidthStyle := ssAuto;
    edPostCode.WidthPercent := 100.000000000000000000;
    SetEvent(edPostCode, Self, 'OnExit', 'edPostCodeExit');
    edPostCode.DataField := 'PostCode';
    edPostCode.DataSource := DataSource;
    edEmail.SetParentComponent(Self);
    edEmail.Name := 'edEmail';
    edEmail.Left := 24;
    edEmail.Top := 217;
    edEmail.Width := 200;
    edEmail.Height := 21;
    edEmail.CharCase := wecLowerCase;
    edEmail.ChildOrder := 16;
    edEmail.ElementClassName := 'form-control';
    edEmail.ElementFont := efCSS;
    edEmail.ElementPosition := epIgnore;
    edEmail.HeightStyle := ssAuto;
    edEmail.HeightPercent := 100.000000000000000000;
    edEmail.TabOrder := 11;
    edEmail.Text := 'edEmail';
    edEmail.WidthStyle := ssAuto;
    edEmail.WidthPercent := 100.000000000000000000;
    SetEvent(edEmail, Self, 'OnChange', 'EditChange');
    edEmail.DataField := 'Email';
    edEmail.DataSource := DataSource;
    edMobile.SetParentComponent(Self);
    edMobile.Name := 'edMobile';
    edMobile.Left := 24;
    edMobile.Top := 244;
    edMobile.Width := 200;
    edMobile.Height := 21;
    edMobile.ChildOrder := 17;
    edMobile.ElementClassName := 'form-control';
    edMobile.ElementFont := efCSS;
    edMobile.ElementPosition := epIgnore;
    edMobile.HeightStyle := ssAuto;
    edMobile.HeightPercent := 100.000000000000000000;
    edMobile.TabOrder := 10;
    edMobile.Text := 'edMobile';
    edMobile.WidthStyle := ssAuto;
    edMobile.WidthPercent := 100.000000000000000000;
    SetEvent(edMobile, Self, 'OnChange', 'EditChange');
    edMobile.DataField := 'Mobile';
    edMobile.DataSource := DataSource;
    edTelephone.SetParentComponent(Self);
    edTelephone.Name := 'edTelephone';
    edTelephone.Left := 24;
    edTelephone.Top := 271;
    edTelephone.Width := 200;
    edTelephone.Height := 21;
    edTelephone.ChildOrder := 18;
    edTelephone.ElementClassName := 'form-control';
    edTelephone.ElementFont := efCSS;
    edTelephone.ElementPosition := epIgnore;
    edTelephone.HeightStyle := ssAuto;
    edTelephone.HeightPercent := 100.000000000000000000;
    edTelephone.TabOrder := 9;
    edTelephone.Text := 'edTelephone';
    edTelephone.WidthStyle := ssAuto;
    edTelephone.WidthPercent := 100.000000000000000000;
    SetEvent(edTelephone, Self, 'OnChange', 'EditChange');
    edTelephone.DataField := 'Telephone';
    edTelephone.DataSource := DataSource;
    ClaimStatus.SetParentComponent(Self);
    ClaimStatus.Name := 'ClaimStatus';
    ClaimStatus.Left := 269;
    ClaimStatus.Top := 256;
    ClaimStatus.Width := 197;
    ClaimStatus.Height := 22;
    ClaimStatus.ElementClassName := 'form-select form-control';
    ClaimStatus.ElementFont := efCSS;
    ClaimStatus.HeightPercent := 100.000000000000000000;
    ClaimStatus.TabOrder := 12;
    ClaimStatus.WidthPercent := 100.000000000000000000;
    SetEvent(ClaimStatus, Self, 'OnChange', 'ClaimStatusChange');
    SetEvent(ClaimStatus, Self, 'OnExit', 'ClaimStatusExit');
    ClaimStatus.DataField := 'ClaimStatus';
    ClaimStatus.DataSource := DataSource;
    ClaimStatus.ListSource := MainData.DummySource;
    Comments.SetParentComponent(Self);
    Comments.Name := 'Comments';
    Comments.Left := 472;
    Comments.Top := 224;
    Comments.Width := 121;
    Comments.Height := 22;
    Comments.ChildOrder := 25;
    Comments.ElementClassName := 'form-control';
    Comments.ElementFont := efCSS;
    Comments.ElementPosition := epIgnore;
    Comments.HeightStyle := ssAuto;
    Comments.HeightPercent := 100.000000000000000000;
    Comments.TabOrder := 13;
    Comments.Text := 'Comments';
    Comments.Visible := False;
    Comments.WidthStyle := ssAuto;
    Comments.WidthPercent := 100.000000000000000000;
    SetEvent(Comments, Self, 'OnChange', 'EditChange');
    Comments.DataField := 'Comments';
    Comments.DataSource := DataSource;
    ContactByPhone.SetParentComponent(Self);
    ContactByPhone.Name := 'ContactByPhone';
    ContactByPhone.Left := 318;
    ContactByPhone.Top := 340;
    ContactByPhone.Width := 113;
    ContactByPhone.Height := 22;
    ContactByPhone.ChildOrder := 27;
    ContactByPhone.ElementClassName := 'form-check-input';
    ContactByPhone.ElementFont := efCSS;
    ContactByPhone.ElementPosition := epIgnore;
    ContactByPhone.HeightStyle := ssAuto;
    ContactByPhone.HeightPercent := 100.000000000000000000;
    ContactByPhone.TabOrder := 14;
    ContactByPhone.WidthStyle := ssAuto;
    ContactByPhone.WidthPercent := 100.000000000000000000;
    ContactByPhone.DataField := 'ContactByPhone';
    ContactByPhone.DataSource := DataSource;
    ContactByEmail.SetParentComponent(Self);
    ContactByEmail.Name := 'ContactByEmail';
    ContactByEmail.Left := 32;
    ContactByEmail.Top := 368;
    ContactByEmail.Width := 113;
    ContactByEmail.Height := 22;
    ContactByEmail.ChildOrder := 27;
    ContactByEmail.ElementClassName := 'form-check-input';
    ContactByEmail.ElementFont := efCSS;
    ContactByEmail.ElementPosition := epIgnore;
    ContactByEmail.HeightStyle := ssAuto;
    ContactByEmail.HeightPercent := 100.000000000000000000;
    ContactByEmail.TabOrder := 16;
    ContactByEmail.WidthStyle := ssAuto;
    ContactByEmail.WidthPercent := 100.000000000000000000;
    ContactByEmail.DataField := 'ContactByEmail';
    ContactByEmail.DataSource := DataSource;
    ContactByText.SetParentComponent(Self);
    ContactByText.Name := 'ContactByText';
    ContactByText.Left := 183;
    ContactByText.Top := 368;
    ContactByText.Width := 113;
    ContactByText.Height := 22;
    ContactByText.ChildOrder := 27;
    ContactByText.ElementClassName := 'form-check-input';
    ContactByText.ElementFont := efCSS;
    ContactByText.ElementPosition := epIgnore;
    ContactByText.HeightStyle := ssAuto;
    ContactByText.HeightPercent := 100.000000000000000000;
    ContactByText.TabOrder := 19;
    ContactByText.WidthStyle := ssAuto;
    ContactByText.WidthPercent := 100.000000000000000000;
    ContactByText.DataField := 'ContactByText';
    ContactByText.DataSource := DataSource;
    ContactByPost.SetParentComponent(Self);
    ContactByPost.Name := 'ContactByPost';
    ContactByPost.Left := 291;
    ContactByPost.Top := 368;
    ContactByPost.Width := 113;
    ContactByPost.Height := 22;
    ContactByPost.ChildOrder := 27;
    ContactByPost.ElementClassName := 'form-check-input';
    ContactByPost.ElementFont := efCSS;
    ContactByPost.ElementPosition := epIgnore;
    ContactByPost.HeightStyle := ssAuto;
    ContactByPost.HeightPercent := 100.000000000000000000;
    ContactByPost.TabOrder := 13;
    ContactByPost.WidthStyle := ssAuto;
    ContactByPost.WidthPercent := 100.000000000000000000;
    ContactByPost.DataField := 'ContactByPost';
    ContactByPost.DataSource := DataSource;
    DataState.Left := 10;
    DataState.Top := 66;
    TitleEdit.SetParentComponent(Self);
    TitleEdit.Name := 'TitleEdit';
    TitleEdit.Left := 24;
    TitleEdit.Top := 134;
    TitleEdit.Width := 200;
    TitleEdit.Height := 21;
    TitleEdit.ChildOrder := 9;
    TitleEdit.ElementClassName := 'form-control';
    TitleEdit.ElementFont := efCSS;
    TitleEdit.ElementPosition := epIgnore;
    TitleEdit.HeightStyle := ssAuto;
    TitleEdit.HeightPercent := 100.000000000000000000;
    TitleEdit.TabOrder := 2;
    TitleEdit.Text := 'Title';
    TitleEdit.WidthStyle := ssAuto;
    TitleEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TitleEdit, Self, 'OnChange', 'EditChange');
    TitleEdit.DataField := 'Title';
    TitleEdit.DataSource := DataSource;
    ExternalIdEdit.SetParentComponent(Self);
    ExternalIdEdit.Name := 'ExternalIdEdit';
    ExternalIdEdit.Left := 258;
    ExternalIdEdit.Top := 296;
    ExternalIdEdit.Width := 200;
    ExternalIdEdit.Height := 21;
    ExternalIdEdit.CharCase := wecUpperCase;
    ExternalIdEdit.ChildOrder := 15;
    ExternalIdEdit.ElementClassName := 'form-control';
    ExternalIdEdit.ElementFont := efCSS;
    ExternalIdEdit.ElementPosition := epIgnore;
    ExternalIdEdit.HeightStyle := ssAuto;
    ExternalIdEdit.HeightPercent := 100.000000000000000000;
    ExternalIdEdit.TabOrder := 8;
    ExternalIdEdit.Text := 'ExternalIdEdit';
    ExternalIdEdit.WidthStyle := ssAuto;
    ExternalIdEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ExternalIdEdit, Self, 'OnExit', 'edPostCodeExit');
    ExternalIdEdit.DataField := 'ExternalId';
    ExternalIdEdit.DataSource := DataSource;
    edCounty.SetParentComponent(Self);
    edCounty.Name := 'edCounty';
    edCounty.Left := 472;
    edCounty.Top := 197;
    edCounty.Width := 200;
    edCounty.Height := 21;
    edCounty.ChildOrder := 15;
    edCounty.ElementClassName := 'form-control';
    edCounty.ElementFont := efCSS;
    edCounty.ElementPosition := epIgnore;
    edCounty.HeightStyle := ssAuto;
    edCounty.HeightPercent := 100.000000000000000000;
    edCounty.TabOrder := 8;
    edCounty.Text := 'edCounty';
    edCounty.WidthStyle := ssAuto;
    edCounty.WidthPercent := 100.000000000000000000;
    SetEvent(edCounty, Self, 'OnChange', 'EditChange');
    edCounty.DataField := 'County';
    edCounty.DataSource := DataSource;
    CountryList.SetParentComponent(Self);
    CountryList.Name := 'CountryList';
    CountryList.Left := 253;
    CountryList.Top := 225;
    CountryList.Width := 200;
    CountryList.Height := 23;
    CountryList.ElementClassName := 'form-select';
    CountryList.ElementPosition := epIgnore;
    CountryList.HeightStyle := ssAuto;
    CountryList.HeightPercent := 100.000000000000000000;
    CountryList.WidthStyle := ssAuto;
    CountryList.WidthPercent := 100.000000000000000000;
    SetEvent(CountryList, Self, 'OnChange', 'CountryListChange');
    SetEvent(CountryList, Self, 'OnExit', 'CountryListExit');
    CountryList.ItemIndex := -1;
    DataSource.Left := 160;
    DataSource.Top := 16;
    CloseTimer.Left := 504;
    CloseTimer.Top := 40;
  finally
    DonorIdLabel.AfterLoadDFMValues;
    ExternalIdLabel.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    edFirstName.AfterLoadDFMValues;
    edLastName.AfterLoadDFMValues;
    edAdd1.AfterLoadDFMValues;
    edAdd2.AfterLoadDFMValues;
    edAdd3.AfterLoadDFMValues;
    edCity.AfterLoadDFMValues;
    edPostCode.AfterLoadDFMValues;
    edEmail.AfterLoadDFMValues;
    edMobile.AfterLoadDFMValues;
    edTelephone.AfterLoadDFMValues;
    ClaimStatus.AfterLoadDFMValues;
    Comments.AfterLoadDFMValues;
    ContactByPhone.AfterLoadDFMValues;
    ContactByEmail.AfterLoadDFMValues;
    ContactByText.AfterLoadDFMValues;
    ContactByPost.AfterLoadDFMValues;
    DataState.AfterLoadDFMValues;
    TitleEdit.AfterLoadDFMValues;
    ExternalIdEdit.AfterLoadDFMValues;
    edCounty.AfterLoadDFMValues;
    CountryList.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
  end;
end;

end.
