unit OtherClaimsCommunityBuildingsEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, OtherClaimsEdit, Data.DB, WEBLib.DB,
  WEBLib.WebCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls, Vcl.Controls,
  WEBLib.ExtCtrls;

type
  TOtherClaimsCommunityBuildingsEditForm = class(TOtherClaimsEditForm)
    BuildingsDataSource: TDataSource;
    BuildingComboBox: TComboBox;
    ConnectedCharityCombo: TComboBox;
    CharityDataSource: TDataSource;
    AddConnectedCharityButton: TButton;
    AddCommunityBuildingButton: TButton;
    RefreshNewCharity: TTimer;
    RefreshNewBuilding: TTimer;
    procedure BuildingComboBoxChange(Sender: TObject);
    procedure ConnectedCharityComboChange(Sender: TObject);
    procedure AddConnectedCharityButtonClick(Sender: TObject);
    procedure AddCommunityBuildingButtonClick(Sender: TObject);
    procedure RefreshNewBuildingTimer(Sender: TObject);
    procedure RefreshNewCharityTimer(Sender: TObject);
    procedure BuildingComboBoxExit(Sender: TObject);
  private
    { Private declarations }
    FNewBuildingName: string;
    FNewCharityName: string;
    procedure SetCommunityBuildingsDataSet(const Value: TDataSet);
    procedure SetConnectedCharityDataSet(const Value: TDataSet);
    procedure CheckValidations;override;
  protected
    [async]
    procedure GetRules; async; override;
    function GetBuildingId: integer;
    procedure SetBuildingNameComboBox(BuildingId: integer);

    function GetCharityId: integer;
    procedure SetCharityNameComboBox(CharityId: integer);

    procedure SetNewCharity;
    procedure SetNewBuilding;


    [async]
    procedure AddConnectedCharity; async;
    [async]
    procedure AddCommunityBuilding; async;

    //procedure Validate;

  public
    { Public declarations }
    procedure SetValidationForEdit;
    property CommunityBuildingsDataSet: TDataSet write SetCommunityBuildingsDataSet;
    property ConnectedCharityDataSet: TDataSet write SetConnectedCharityDataSet;
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsCommunityBuildingsEditForm: TOtherClaimsCommunityBuildingsEditForm;

implementation

{$R *.dfm}

uses
  OtherClaimsCommunityBuildingsList, CommunityBuildingEdit, ConnectedCharitiesEdit,
  GA.Web.Utils, SMX.Web.Document.Utils;


procedure TOtherClaimsCommunityBuildingsEditForm.BuildingComboBoxChange(Sender:
    TObject);
begin
  GetBuildingId;
  CheckValidations;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.BuildingComboBoxExit(
  Sender: TObject);
begin
//  inherited;
  CheckValidations;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.ConnectedCharityComboChange(
    Sender: TObject);
begin
  GetCharityId;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.GetRules;
begin
  OtherClaimsType := 'CommunityBuildings';
  inherited;

end;

procedure TOtherClaimsCommunityBuildingsEditForm.SetCommunityBuildingsDataSet(
  const Value: TDataSet);
var
  BuildingName: string;
  BuildingId: integer;
  CurrentBuildingId: integer;
begin
  BuildingsDataSource.DataSet := Value;
  BuildingsDataSource.DataSet.First;

  while not BuildingsDataSource.DataSet.Eof do
  begin
    BuildingName := BuildingsDataSource.DataSet.FieldByName('BuildingName').AsString;
    BuildingId := BuildingsDataSource.DataSet.FieldByName('Id').AsInteger;
    BuildingComboBox.Items.AddPair(BuildingName, BuildingId.ToString);
    BuildingsDataSource.DataSet.Next;
  end;

  CurrentBuildingId := EditDataSource.DataSet.FieldByName('BuildingId').AsInteger;

  if CurrentBuildingId > 0 then
    SetBuildingNameComboBox(CurrentBuildingId);
end;

procedure TOtherClaimsCommunityBuildingsEditForm.SetBuildingNameComboBox(
  BuildingId: integer);
var
  Idx: integer;
  ValueStr: string;
begin
  for Idx := 0 to BuildingComboBox.Items.Count-1 do
  begin
    ValueStr := BuildingComboBox.Values[Idx];

    try
      if ValueStr.ToInteger() = BuildingId then
      begin
        BuildingComboBox.ItemIndex := Idx;
        break;
      end;
    except

    end;
  end;
end;

function TOtherClaimsCommunityBuildingsEditForm.GetBuildingId: integer;
var
  Idx: integer;
  ValueStr: string;
begin
  ValueStr := BuildingComboBox.Values[BuildingComboBox.ItemIndex];
  EditDataSource.DataSet.FieldByName('BuildingId').AsInteger := ValueStr.ToInteger;
end;


procedure TOtherClaimsCommunityBuildingsEditForm.SetConnectedCharityDataSet(
  const Value: TDataSet);
var
  CharityName: string;
  CharityId: integer;
  CurrentCharityId: integer;
begin
  CharityDataSource.DataSet := Value;
  CharityDataSource.DataSet.First;

  ConnectedCharityCombo.Items.AddPair('None', '-1');

  while not CharityDataSource.DataSet.Eof do
  begin
    CharityName := CharityDataSource.DataSet.FieldByName('CharityName').AsString;
    CharityId := CharityDataSource.DataSet.FieldByName('Id').AsInteger;
    ConnectedCharityCombo.Items.AddPair(CharityName, CharityId.ToString);
    CharityDataSource.DataSet.Next;
  end;

  CurrentCharityId := EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger;

  if CurrentCharityId > 0 then
    SetCharityNameComboBox(CurrentCharityId);
end;

function TOtherClaimsCommunityBuildingsEditForm.GetCharityId: integer;
var
  Idx: integer;
  ValueStr: string;
begin
  ValueStr := ConnectedCharityCombo.Values[ConnectedCharityCombo.ItemIndex];
  EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger := ValueStr.ToInteger;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.SetCharityNameComboBox(
  CharityId: integer);
var
  Idx: integer;
  ValueStr: string;
begin
  for Idx := 0 to ConnectedCharityCombo.Items.Count-1 do
  begin
    ValueStr := ConnectedCharityCombo.Values[Idx];

    try
      if ValueStr.ToInteger() = CharityId then
      begin
        ConnectedCharityCombo.ItemIndex := Idx;
        break;
      end;
    except

    end;
  end;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.AddConnectedCharityButtonClick(Sender:
    TObject);
begin
  inherited;
  AddConnectedCharity;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.AddCommunityBuildingButtonClick(Sender:
    TObject);
begin
  inherited;
  AddCommunityBuilding;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.AddCommunityBuilding;
var
  Aform: TCommunityBuildingEditForm;

begin
  AForm := TCommunityBuildingEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    BuildingsDataSource.DataSet.Insert;
    AForm.EditDataSet := BuildingsDataSource.DataSet;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    if AForm.ModalResult = mrOk then
    begin
      FNewBuildingName := AForm.BuildingNameEdit.Text;
      BuildingsDataSource.DataSet.Post;
    end;
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.AddConnectedCharity;
var
  Aform: TConnectedCharityEditForm;

begin
  AForm := TConnectedCharityEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    CharityDataSource.DataSet.Insert;
    AForm.EditDataSet := CharityDataSource.DataSet;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    if AForm.ModalResult = mrOk then
    begin
      FNewCharityName := AForm.CharityNameEdit.Text;
      CharityDataSource.DataSet.Post;
    end;
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.RefreshNewBuildingTimer(
    Sender: TObject);
begin
  RefreshNewBuilding.Enabled := false;
  SetNewBuilding;
  GetBuildingId;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.RefreshNewCharityTimer(Sender:
    TObject);
begin
  RefreshNewCharity.Enabled := false;
  SetNewCharity;
  GetCharityId;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.SetNewCharity;
var
  CharityName: string;
  CharityId: integer;
  CurrentCharityId: integer;
  nIdx: integer;
  sCName: string;
begin
  ConnectedCharityCombo.Clear;
  CharityDataSource.DataSet.First;
  ConnectedCharityCombo.Items.AddPair('None', '-1');

  while not CharityDataSource.DataSet.Eof do
  begin
    CharityName := CharityDataSource.DataSet.FieldByName('CharityName').AsString;
    CharityId := CharityDataSource.DataSet.FieldByName('Id').AsInteger;
    ConnectedCharityCombo.Items.AddPair(CharityName, CharityId.ToString);
    CharityDataSource.DataSet.Next;
  end;
  CurrentCharityId := EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger;

  if CurrentCharityId > 0 then
    SetCharityNameComboBox(CurrentCharityId);

  if FNewCharityName <> '' then
  begin
    sCName := FNewCharityName;
    nIdx := ConnectedCharityCombo.Items.IndexOfName(sCName);
    ConnectedCharityCombo.ItemIndex := nIdx;
    EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger := GetCharityId;
  end;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.SetNewBuilding;
var
  BuildingName: string;
  BuildingId: integer;
  CurrentBuildingId: integer;
  nIdx: integer;
  sCName: string;
begin
  BuildingComboBox.Clear;
  BuildingsDataSource.DataSet.First;
  BuildingComboBox.Items.AddPair('None', '-1');

  while not BuildingsDataSource.DataSet.Eof do
  begin
    BuildingName := BuildingsDataSource.DataSet.FieldByName('BuildingName').AsString;
    BuildingId := BuildingsDataSource.DataSet.FieldByName('Id').AsInteger;
    BuildingComboBox.Items.AddPair(BuildingName, BuildingId.ToString);
    BuildingsDataSource.DataSet.Next;
  end;
  CurrentBuildingId := EditDataSource.DataSet.FieldByName('BuildingId').AsInteger;

  if CurrentBuildingId > 0 then
    SetCharityNameComboBox(CurrentBuildingId);

  if FNewBuildingName <> '' then
  begin
    sCName := FNewBuildingName;
    nIdx := BuildingComboBox.Items.IndexOfName(sCName);
    BuildingComboBox.ItemIndex := nIdx;
    EditDataSource.DataSet.FieldByName('BuildingId').AsInteger := GetBuildingId;
  end;
  CheckValidations;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.CheckValidations;
var
  lValid: boolean;
begin
  inherited;

  lValid := GetBaseValidation;

  if (BuildingComboBox.ItemIndex > -1) then
    TDocUtils.setControlValidity('BuildingComboBox', vsValid)
  else
  begin
    TDocUtils.setControlValidity('BuildingComboBox', vsInvalid);
    lValid := false;
  end;

  SaveButton.Enabled := lValid;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.SetValidationForEdit;
begin
  if EditDataSource.DataSet.State = dsEdit then
  begin
    SetBaseValidations(True);
    CheckValidations;
    ResetButtons(True);
  end;
end;



procedure TOtherClaimsCommunityBuildingsEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BuildingComboBox := TComboBox.Create('BuildingComboBox');
  ConnectedCharityCombo := TComboBox.Create('ConnectedCharityCombo');
  AddConnectedCharityButton := TButton.Create('AddConnectedCharityButton');
  AddCommunityBuildingButton := TButton.Create('AddCommunityBuildingButton');
  BuildingsDataSource := TDataSource.Create(Self);
  CharityDataSource := TDataSource.Create(Self);
  RefreshNewCharity := TTimer.Create(Self);
  RefreshNewBuilding := TTimer.Create(Self);

  FormCaption.BeforeLoadDFMValues;
  ErrorMessageTransactionDate.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  AmountEdit.BeforeLoadDFMValues;
  TransactionDateEdit.BeforeLoadDFMValues;
  NoteMemo.BeforeLoadDFMValues;
  BuildingComboBox.BeforeLoadDFMValues;
  ConnectedCharityCombo.BeforeLoadDFMValues;
  AddConnectedCharityButton.BeforeLoadDFMValues;
  AddCommunityBuildingButton.BeforeLoadDFMValues;
  BuildingsDataSource.BeforeLoadDFMValues;
  CharityDataSource.BeforeLoadDFMValues;
  RefreshNewCharity.BeforeLoadDFMValues;
  RefreshNewBuilding.BeforeLoadDFMValues;
  try
    Visible := True;
    FormCaption.Width := 145;
    FormCaption.Caption := 'Community Building Claim';
    FormCaption.Visible := False;
    ErrorMessageTransactionDate.Visible := False;
    CloseButton.TabOrder := 7;
    SaveButton.ElementClassName := 'btn btn-secondary';
    SaveButton.TabOrder := 5;
    CancelButton.ElementClassName := 'btn btn-primary';
    CancelButton.TabOrder := 6;
    AmountEdit.Left := 8;
    AmountEdit.Top := 148;
    AmountEdit.TabOrder := 3;
    TransactionDateEdit.Left := 8;
    TransactionDateEdit.Top := 112;
    TransactionDateEdit.TabOrder := 2;
    NoteMemo.Left := 8;
    NoteMemo.Top := 192;
    NoteMemo.TabOrder := 4;
    BuildingComboBox.SetParentComponent(Self);
    BuildingComboBox.Name := 'BuildingComboBox';
    BuildingComboBox.Left := 8;
    BuildingComboBox.Top := 83;
    BuildingComboBox.Width := 185;
    BuildingComboBox.Height := 23;
    BuildingComboBox.ElementClassName := 'form-select';
    BuildingComboBox.ElementPosition := epIgnore;
    BuildingComboBox.HeightStyle := ssAuto;
    BuildingComboBox.HeightPercent := 100.000000000000000000;
    BuildingComboBox.TabOrder := 1;
    BuildingComboBox.Text := 'BuildingComboBox';
    BuildingComboBox.WidthStyle := ssAuto;
    BuildingComboBox.WidthPercent := 100.000000000000000000;
    SetEvent(BuildingComboBox, Self, 'OnChange', 'BuildingComboBoxChange');
    SetEvent(BuildingComboBox, Self, 'OnExit', 'BuildingComboBoxExit');
    BuildingComboBox.ItemIndex := -1;
    ConnectedCharityCombo.SetParentComponent(Self);
    ConnectedCharityCombo.Name := 'ConnectedCharityCombo';
    ConnectedCharityCombo.Left := 16;
    ConnectedCharityCombo.Top := 400;
    ConnectedCharityCombo.Width := 185;
    ConnectedCharityCombo.Height := 23;
    ConnectedCharityCombo.ElementClassName := 'form-select';
    ConnectedCharityCombo.HeightPercent := 100.000000000000000000;
    ConnectedCharityCombo.Text := 'ConnectedCharityCombo';
    ConnectedCharityCombo.WidthPercent := 100.000000000000000000;
    SetEvent(ConnectedCharityCombo, Self, 'OnChange', 'ConnectedCharityComboChange');
    ConnectedCharityCombo.ItemIndex := -1;
    AddConnectedCharityButton.SetParentComponent(Self);
    AddConnectedCharityButton.Name := 'AddConnectedCharityButton';
    AddConnectedCharityButton.Left := 224;
    AddConnectedCharityButton.Top := 399;
    AddConnectedCharityButton.Width := 96;
    AddConnectedCharityButton.Height := 25;
    AddConnectedCharityButton.Caption := 'Add Connected Charity';
    AddConnectedCharityButton.ChildOrder := 11;
    AddConnectedCharityButton.ElementClassName := 'btn btn-secondary';
    AddConnectedCharityButton.ElementFont := efCSS;
    AddConnectedCharityButton.ElementPosition := epIgnore;
    AddConnectedCharityButton.HeightStyle := ssAuto;
    AddConnectedCharityButton.HeightPercent := 100.000000000000000000;
    AddConnectedCharityButton.WidthStyle := ssAuto;
    AddConnectedCharityButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddConnectedCharityButton, Self, 'OnClick', 'AddConnectedCharityButtonClick');
    AddCommunityBuildingButton.SetParentComponent(Self);
    AddCommunityBuildingButton.Name := 'AddCommunityBuildingButton';
    AddCommunityBuildingButton.Left := 326;
    AddCommunityBuildingButton.Top := 399;
    AddCommunityBuildingButton.Width := 96;
    AddCommunityBuildingButton.Height := 25;
    AddCommunityBuildingButton.Caption := 'Add Community Building';
    AddCommunityBuildingButton.ChildOrder := 12;
    AddCommunityBuildingButton.ElementClassName := 'btn btn-secondary';
    AddCommunityBuildingButton.ElementFont := efCSS;
    AddCommunityBuildingButton.ElementPosition := epIgnore;
    AddCommunityBuildingButton.HeightStyle := ssAuto;
    AddCommunityBuildingButton.HeightPercent := 100.000000000000000000;
    AddCommunityBuildingButton.WidthStyle := ssAuto;
    AddCommunityBuildingButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddCommunityBuildingButton, Self, 'OnClick', 'AddCommunityBuildingButtonClick');
    BuildingsDataSource.SetParentComponent(Self);
    BuildingsDataSource.Name := 'BuildingsDataSource';
    BuildingsDataSource.Left := 478;
    BuildingsDataSource.Top := 152;
    CharityDataSource.SetParentComponent(Self);
    CharityDataSource.Name := 'CharityDataSource';
    CharityDataSource.Left := 478;
    CharityDataSource.Top := 224;
    RefreshNewCharity.SetParentComponent(Self);
    RefreshNewCharity.Name := 'RefreshNewCharity';
    RefreshNewCharity.Enabled := False;
    SetEvent(RefreshNewCharity, Self, 'OnTimer', 'RefreshNewCharityTimer');
    RefreshNewCharity.Left := 544;
    RefreshNewCharity.Top := 288;
    RefreshNewBuilding.SetParentComponent(Self);
    RefreshNewBuilding.Name := 'RefreshNewBuilding';
    RefreshNewBuilding.Enabled := False;
    SetEvent(RefreshNewBuilding, Self, 'OnTimer', 'RefreshNewBuildingTimer');
    RefreshNewBuilding.Left := 544;
    RefreshNewBuilding.Top := 360;
  finally
    FormCaption.AfterLoadDFMValues;
    ErrorMessageTransactionDate.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    AmountEdit.AfterLoadDFMValues;
    TransactionDateEdit.AfterLoadDFMValues;
    NoteMemo.AfterLoadDFMValues;
    BuildingComboBox.AfterLoadDFMValues;
    ConnectedCharityCombo.AfterLoadDFMValues;
    AddConnectedCharityButton.AfterLoadDFMValues;
    AddCommunityBuildingButton.AfterLoadDFMValues;
    BuildingsDataSource.AfterLoadDFMValues;
    CharityDataSource.AfterLoadDFMValues;
    RefreshNewCharity.AfterLoadDFMValues;
    RefreshNewBuilding.AfterLoadDFMValues;
  end;
end;

end.
