unit CharityEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Data.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, Vcl.Controls, Vcl.StdCtrls, WEBLib.ExtCtrls,
  WEBLib.DBCtrls, WEBLib.StdCtrls, MainDataModule;

type
  TCharityEditForm = class(TCoreWebForm)
    MainDataset: TXDataWebDataSet;
    DataSource: TDataSource;
    edOrganisationName: TDBEdit;
    cbCharityType: TDBLookupComboBox;
    edGatewayId: TDBEdit;
    cbRegulator: TDBLookupComboBox;
    edOtherRegulator: TDBEdit;
    edRegulatorNumber: TDBEdit;
    edEndOfYear: TDBEdit;
    cbOrganisationType: TDBLookupComboBox;
    cbSubmissionMethod: TDBLookupComboBox;
    cbGASDSelection: TDBLookupComboBox;
    EditButton: TButton;
    CloseButton: TButton;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetHMRCOfficial: TIntegerField;
    MainDatasetHMRCRef: TStringField;
    MainDatasetCharityType: TStringField;
    MainDatasetGatewayId: TStringField;
    MainDatasetRegulator: TStringField;
    MainDatasetOtherRegulator: TStringField;
    MainDatasetRegulatorNumber: TStringField;
    MainDatasetEndOfYear: TStringField;
    MainDatasetGatewayPassword: TStringField;
    MainDatasetOrganisationType: TStringField;
    MainDatasetOrganisationName: TStringField;
    MainDatasetSubmissionMethod: TStringField;
    MainDatasetRegulatorStatus: TStringField;
    MainDatasetGASDSelection: TStringField;
    MainDatasetLockDate: TDateTimeField;
    SaveButton: TButton;
    edHMRCReference: TDBEdit;
    AuthorisedOfficial: TLookupComboBox;
    procedure CloseButtonClick(Sender: TObject);
    procedure DataSourceStateChange(Sender: TObject);
    procedure edEndOfYearExit(Sender: TObject);
    procedure edEndOfYearKeyPress(Sender: TObject; var Key: Char);
    procedure EditButtonClick(Sender: TObject);
    procedure MainDatasetAfterOpen(DataSet: TDataSet);
    procedure SaveButtonClick(Sender: TObject);
    procedure MainDatasetAfterPost(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
    procedure AuthorisedOfficialChange(Sender: TObject);
  private
    { Private declarations }
    procedure SetUpDropDowns;
    procedure SetFieldsToReadOnly;
    function VerifyEndOfYear(var AEndOfYear: string):boolean;
    [async]
    procedure SearchForAuthOfficials; async;
public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  CharityEditForm: TCharityEditForm;

implementation

{$R *.dfm}

uses
  SMX.Web.Document.Utils, XData.Web.Client;

procedure TCharityEditForm.AuthorisedOfficialChange(Sender: TObject);
begin
  inherited;
  SaveButton.Enabled := true;
end;

procedure TCharityEditForm.CloseButtonClick(Sender: TObject);
begin
  SelfClose;
//  ModalResult := mrOk;
end;

procedure TCharityEditForm.DataSourceStateChange(Sender: TObject);
begin
  SaveButton.Enabled := (Datasource.State in dsEditModes);
end;

procedure TCharityEditForm.edEndOfYearExit(Sender: TObject);
var
  lEndOfYear: string;
begin
  lEndOfYear := edEndOfYear.Text;

  if VerifyEndOfYear(lEndOfYear) then
  begin
    edEndOfYear.Text := lEndOfYear;
    TDocUtils.setControlValidity('EndOfYearEdit', vsValid);
  end
  else
  begin
    edEndOfYear.Text := '';
    TDocUtils.setControlValidity('EndOfYearEdit', vsInvalid);
  end
end;

procedure TCharityEditForm.edEndOfYearKeyPress(Sender: TObject; var Key: Char);
var
  AlreadyAdded: boolean;
begin
  AlreadyAdded := Pos('/', edEndOfYear.Text) <> 0;

  if (not (Key in ['0'..'9', '/'])) or ((Key = '/') AND AlreadyAdded) then
  begin
    Key := #0;
  end;
end;

procedure TCharityEditForm.MainDatasetAfterOpen(DataSet: TDataSet);
begin
  inherited;
  SetFieldsToReadOnly;
  SearchForAuthOfficials;
end;

procedure TCharityEditForm.SaveButtonClick(Sender: TObject);
var
  lOfficialId: string;
begin
  lOfficialId := AuthorisedOfficial.Value;

  if lOfficialId <> '' then
    MainDataset.FieldByName('HMRCOfficial').AsInteger := lOfficialId.ToInteger;

  MainDataSet.Post;
  SaveButton.Enabled := false;
end;

procedure TCharityEditForm.SetUpDropDowns;
begin
  cbCharityType.LookupValues.AddPair('CharitableTrust', 'Charitable Trust');
  cbCharityType.LookupValues.AddPair('Other', 'Other');

  cbRegulator.LookupValues.AddPair('CCEW', 'Charity Commission for England and Wales');
  cbRegulator.LookupValues.AddPair('CCNI', 'Charity Commission for Northern Ireland');
  cbRegulator.LookupValues.AddPair('OSCR', 'Office of the ScottishCharity Regulator');
  cbRegulator.LookupValues.AddPair('OTH', 'Other');
  cbRegulator.LookupValues.AddPair('None', 'None');

  cbOrganisationType.LookupValues.AddPair('Individual', 'Individual');
  cbOrganisationType.LookupValues.AddPair('Company', 'Company');
  cbOrganisationType.LookupValues.AddPair('Agent', 'Agent');
  cbOrganisationType.LookupValues.AddPair('Bureau', 'Bureau');
  cbOrganisationType.LookupValues.AddPair('Partnership', 'Partnership');
  cbOrganisationType.LookupValues.AddPair('Trust', 'Trust');
  cbOrganisationType.LookupValues.AddPair('Employer', 'Employer');
  cbOrganisationType.LookupValues.AddPair('Government', 'Government');
  cbOrganisationType.LookupValues.AddPair('ActingInCapacity', 'Acting In Capacity');
  cbOrganisationType.LookupValues.AddPair('Other', 'Other');

//  cbRegulatorStatus.LookupValues.AddPair('unknown', 'Unknown');
//  cbRegulatorStatus.LookupValues.AddPair('active', 'Active');
//  cbRegulatorStatus.LookupValues.AddPair('inactive', 'Inactive');

  cbGASDSelection.LookupValues.AddPair('GASD', 'Gift Aid Small Donations');
  cbGASDSelection.LookupValues.AddPair('CommunityBuildings', 'Community Buildings');

  cbSubmissionMethod.LookupValues.AddPair('Live', 'Live');
  cbSubmissionMethod.LookupValues.AddPair('LTS', 'LTS');
  cbSubmissionMethod.LookupValues.AddPair('ISV', 'ISV');

  MainDataset.QueryString := '$filter=Id eq 1';
  MainDataset.Load;

end;

function TCharityEditForm.VerifyEndOfYear(var AEndOfYear: string): boolean;
var
  lOk: boolean;
  lPos: integer;
  lDayStr: string;
  lMonthStr: string;
  lDay: integer;
  lMonth: integer;
begin
  lOk := true;
  lPos := Pos('/', AEndOfYear);

  try
    if (lPos <> 2) and (lPos <> 3) then
    begin
      lOk := false;
    end
    else
    begin
      lDayStr := Copy(AEndOfYear, 1, lPos-1);
      lMonthStr := Copy(AEndOfYear, lPos+1, Length(AEndOfYear)-lPos);

      lDay := strtoint(lDayStr);
      lMonth := strtoint(lMonthStr);

      if (lMonth > 12) or (lMonth = 0) or (lDay = 0) then
        lOk := false
      else
      begin
        if lMonth in [1,3,5,7,8,10,12] then
        begin
          if lDay > 31 then
            lOk := false;
        end
        else if lMonth in [4,6,9,11] then
        begin
          if lDay > 30 then
            lOk := false;
        end
        else if lMonth in [2] then
        begin
          if lDay > 28 then
            lOk := false;
        end;

        if lOk then
        begin
          AEndOfYear := Format( '%0.2d/%0.2d', [lDay,lMonth]);
          lOk := true;
        end;
      end;
    end;
  except
    lOk := false;
  end;
  result := lOk;
end;


procedure TCharityEditForm.EditButtonClick(Sender: TObject);
begin
  inherited;
  SaveButton.Enabled := true;
  MainDataset.Edit;
end;

procedure TCharityEditForm.MainDatasetAfterPost(DataSet: TDataSet);
begin
  MainDataset.ApplyUpdates;
end;

procedure TCharityEditForm.WebFormShow(Sender: TObject);
begin
  SetUpDropDowns;
end;

procedure TCharityEditForm.SetFieldsToReadOnly;
var
  tdTimeDate: TDateTime;
begin
  if not MainDataset.EOF then
  begin
    if MainDataset.FieldByName('LockDate').AsDateTime <> 0 then
    //if MainDataset.FieldByName('LockDate').AsDateTime = 0 then
    begin
      //Regulator Number, HMRCRef, GatewayId
      edRegulatorNumber.ReadOnly := true;
      edGatewayId.ReadOnly := true;
      edHMRCReference.ReadOnly := true;
    end;
  end;
end;

procedure TCharityEditForm.SearchForAuthOfficials;
//const
//  _Filter = '$filter=(Status eq ''Active'') and (HMRCValidation eq ''Validated'')';
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;

  lList: JS.TJSArray;

  lIdx: integer;
  lInternalId: integer;
  lFirstName: string;
  lSecondName: string;
  lUserName: string;
  OffUsers: JS.TJSObject;
  lHMRCOfficialId: integer;
begin

  lHMRCOfficialId := MainDataset.FieldByName('HMRCOfficial').AsInteger;

  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IUserManagerService.GetHMRCAuthorisedUsers', [30]));
  lResult := lRetval.ResultAsObject;
  lList := JS.toArray(lResult['value']);

  if lList <> nil then
  begin
    for lIdx := 0 to lList.Length do
    begin
      OffUsers := JS.toObject(lList.Elements[lIdx]);

      lInternalId := JS.toInteger(OffUsers['InternalId']);
      lFirstName := JS.toString(OffUsers['GivenName']);
      lSecondName := JS.toString(OffUsers['LastName']);

      if lHMRCOfficialId = lInternalId then
        AuthorisedOfficial.ItemIndex := lIdx;

      lUserName := lFirstName + ' ' + lSecondName;
      //AuthorisedOfficial.LookupValues.AddPair(inttostr(lInternalId), lUserName);
      AuthorisedOfficial.LookupValues.AddPair(inttostr(lInternalId), lUserName);
    end;
  end;
end;

procedure TCharityEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  edOrganisationName := TDBEdit.Create('OrganisationNameEdit');
  cbCharityType := TDBLookupComboBox.Create('ClaimType');
  edGatewayId := TDBEdit.Create('GatewayIdEdit');
  cbRegulator := TDBLookupComboBox.Create('RegulatorCombo');
  edOtherRegulator := TDBEdit.Create('OtherRegulatorEdit');
  edRegulatorNumber := TDBEdit.Create('RegulatorNumberEdit');
  edEndOfYear := TDBEdit.Create('EndOfYearEdit');
  cbOrganisationType := TDBLookupComboBox.Create('OrganisationTypeCombo');
  cbSubmissionMethod := TDBLookupComboBox.Create('SubmissionMethodCombo');
  cbGASDSelection := TDBLookupComboBox.Create('GASDSelectionCombo');
  EditButton := TButton.Create('EditButton');
  CloseButton := TButton.Create('CloseButton');
  SaveButton := TButton.Create('SaveButton');
  edHMRCReference := TDBEdit.Create('HMRCRefEdit');
  AuthorisedOfficial := TLookupComboBox.Create('HMRCOfficialEdit');
  MainDataset := TXDataWebDataSet.Create(Self);
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetHMRCOfficial := TIntegerField.Create(Self);
  MainDatasetHMRCRef := TStringField.Create(Self);
  MainDatasetCharityType := TStringField.Create(Self);
  MainDatasetGatewayId := TStringField.Create(Self);
  MainDatasetRegulator := TStringField.Create(Self);
  MainDatasetOtherRegulator := TStringField.Create(Self);
  MainDatasetRegulatorNumber := TStringField.Create(Self);
  MainDatasetEndOfYear := TStringField.Create(Self);
  MainDatasetGatewayPassword := TStringField.Create(Self);
  MainDatasetOrganisationType := TStringField.Create(Self);
  MainDatasetOrganisationName := TStringField.Create(Self);
  MainDatasetSubmissionMethod := TStringField.Create(Self);
  MainDatasetRegulatorStatus := TStringField.Create(Self);
  MainDatasetGASDSelection := TStringField.Create(Self);
  MainDatasetLockDate := TDateTimeField.Create(Self);
  DataSource := TDataSource.Create(Self);

  edOrganisationName.BeforeLoadDFMValues;
  cbCharityType.BeforeLoadDFMValues;
  edGatewayId.BeforeLoadDFMValues;
  cbRegulator.BeforeLoadDFMValues;
  edOtherRegulator.BeforeLoadDFMValues;
  edRegulatorNumber.BeforeLoadDFMValues;
  edEndOfYear.BeforeLoadDFMValues;
  cbOrganisationType.BeforeLoadDFMValues;
  cbSubmissionMethod.BeforeLoadDFMValues;
  cbGASDSelection.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  edHMRCReference.BeforeLoadDFMValues;
  AuthorisedOfficial.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetHMRCOfficial.BeforeLoadDFMValues;
  MainDatasetHMRCRef.BeforeLoadDFMValues;
  MainDatasetCharityType.BeforeLoadDFMValues;
  MainDatasetGatewayId.BeforeLoadDFMValues;
  MainDatasetRegulator.BeforeLoadDFMValues;
  MainDatasetOtherRegulator.BeforeLoadDFMValues;
  MainDatasetRegulatorNumber.BeforeLoadDFMValues;
  MainDatasetEndOfYear.BeforeLoadDFMValues;
  MainDatasetGatewayPassword.BeforeLoadDFMValues;
  MainDatasetOrganisationType.BeforeLoadDFMValues;
  MainDatasetOrganisationName.BeforeLoadDFMValues;
  MainDatasetSubmissionMethod.BeforeLoadDFMValues;
  MainDatasetRegulatorStatus.BeforeLoadDFMValues;
  MainDatasetGASDSelection.BeforeLoadDFMValues;
  MainDatasetLockDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  try
    Width := 1233;
    Height := 895;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    edOrganisationName.SetParentComponent(Self);
    edOrganisationName.Name := 'edOrganisationName';
    edOrganisationName.Left := 22;
    edOrganisationName.Top := 24;
    edOrganisationName.Width := 282;
    edOrganisationName.Height := 22;
    edOrganisationName.Hint := 'Organisation Name';
    edOrganisationName.ChildOrder := 9;
    edOrganisationName.ElementClassName := 'form-control';
    edOrganisationName.ElementPosition := epIgnore;
    edOrganisationName.HeightStyle := ssAuto;
    edOrganisationName.HeightPercent := 100.000000000000000000;
    edOrganisationName.ShowHint := True;
    edOrganisationName.TabOrder := 1;
    edOrganisationName.Text := 'edOrganisationName';
    edOrganisationName.WidthStyle := ssAuto;
    edOrganisationName.WidthPercent := 100.000000000000000000;
    edOrganisationName.DataField := 'OrganisationName';
    edOrganisationName.DataSource := DataSource;
    cbCharityType.SetParentComponent(Self);
    cbCharityType.Name := 'cbCharityType';
    cbCharityType.Left := 23;
    cbCharityType.Top := 80;
    cbCharityType.Width := 197;
    cbCharityType.Height := 22;
    cbCharityType.Hint := 'Charity Type';
    cbCharityType.ElementClassName := 'form-select form-control';
    cbCharityType.ElementFont := efCSS;
    cbCharityType.ElementPosition := epIgnore;
    cbCharityType.HeightStyle := ssAuto;
    cbCharityType.HeightPercent := 100.000000000000000000;
    cbCharityType.ShowHint := True;
    cbCharityType.TabOrder := 12;
    cbCharityType.WidthStyle := ssAuto;
    cbCharityType.WidthPercent := 100.000000000000000000;
    cbCharityType.DataField := 'CharityType';
    cbCharityType.DataSource := DataSource;
    cbCharityType.ListSource := MainData.DummySource;
    edGatewayId.SetParentComponent(Self);
    edGatewayId.Name := 'edGatewayId';
    edGatewayId.Left := 22;
    edGatewayId.Top := 108;
    edGatewayId.Width := 282;
    edGatewayId.Height := 22;
    edGatewayId.Hint := 'Gateway ID';
    edGatewayId.ChildOrder := 9;
    edGatewayId.ElementClassName := 'form-control';
    edGatewayId.ElementPosition := epIgnore;
    edGatewayId.HeightStyle := ssAuto;
    edGatewayId.HeightPercent := 100.000000000000000000;
    edGatewayId.ShowHint := True;
    edGatewayId.Text := 'edGatewayId';
    edGatewayId.WidthStyle := ssAuto;
    edGatewayId.WidthPercent := 100.000000000000000000;
    edGatewayId.DataField := 'GatewayId';
    edGatewayId.DataSource := DataSource;
    cbRegulator.SetParentComponent(Self);
    cbRegulator.Name := 'cbRegulator';
    cbRegulator.Left := 23;
    cbRegulator.Top := 136;
    cbRegulator.Width := 197;
    cbRegulator.Height := 22;
    cbRegulator.Hint := 'Regulator';
    cbRegulator.ElementClassName := 'form-select form-control';
    cbRegulator.ElementFont := efCSS;
    cbRegulator.ElementPosition := epIgnore;
    cbRegulator.HeightStyle := ssAuto;
    cbRegulator.HeightPercent := 100.000000000000000000;
    cbRegulator.ShowHint := True;
    cbRegulator.TabOrder := 12;
    cbRegulator.WidthStyle := ssAuto;
    cbRegulator.WidthPercent := 100.000000000000000000;
    cbRegulator.DataField := 'Regulator';
    cbRegulator.DataSource := DataSource;
    cbRegulator.ListSource := MainData.DummySource;
    edOtherRegulator.SetParentComponent(Self);
    edOtherRegulator.Name := 'edOtherRegulator';
    edOtherRegulator.Left := 23;
    edOtherRegulator.Top := 164;
    edOtherRegulator.Width := 282;
    edOtherRegulator.Height := 22;
    edOtherRegulator.Hint := 'Other Regulator';
    edOtherRegulator.ChildOrder := 9;
    edOtherRegulator.ElementClassName := 'form-control';
    edOtherRegulator.ElementPosition := epIgnore;
    edOtherRegulator.HeightStyle := ssAuto;
    edOtherRegulator.HeightPercent := 100.000000000000000000;
    edOtherRegulator.ShowHint := True;
    edOtherRegulator.Text := 'edOtherRegulator';
    edOtherRegulator.WidthStyle := ssAuto;
    edOtherRegulator.WidthPercent := 100.000000000000000000;
    edOtherRegulator.DataField := 'OtherRegulator';
    edOtherRegulator.DataSource := DataSource;
    edRegulatorNumber.SetParentComponent(Self);
    edRegulatorNumber.Name := 'edRegulatorNumber';
    edRegulatorNumber.Left := 22;
    edRegulatorNumber.Top := 192;
    edRegulatorNumber.Width := 282;
    edRegulatorNumber.Height := 22;
    edRegulatorNumber.Hint := 'Regulator Number';
    edRegulatorNumber.ChildOrder := 9;
    edRegulatorNumber.ElementClassName := 'form-control';
    edRegulatorNumber.ElementPosition := epIgnore;
    edRegulatorNumber.HeightStyle := ssAuto;
    edRegulatorNumber.HeightPercent := 100.000000000000000000;
    edRegulatorNumber.ShowHint := True;
    edRegulatorNumber.Text := 'edRegulatorNumber';
    edRegulatorNumber.WidthStyle := ssAuto;
    edRegulatorNumber.WidthPercent := 100.000000000000000000;
    edRegulatorNumber.DataField := 'RegulatorNumber';
    edRegulatorNumber.DataSource := DataSource;
    edEndOfYear.SetParentComponent(Self);
    edEndOfYear.Name := 'edEndOfYear';
    edEndOfYear.Left := 22;
    edEndOfYear.Top := 220;
    edEndOfYear.Width := 107;
    edEndOfYear.Height := 22;
    edEndOfYear.Hint := 'End Of Year';
    edEndOfYear.ChildOrder := 9;
    edEndOfYear.ElementClassName := 'form-control';
    edEndOfYear.ElementPosition := epIgnore;
    edEndOfYear.HeightStyle := ssAuto;
    edEndOfYear.HeightPercent := 100.000000000000000000;
    edEndOfYear.MaxLength := 5;
    edEndOfYear.ShowHint := True;
    edEndOfYear.Text := 'edEndOfYear';
    edEndOfYear.WidthStyle := ssAuto;
    edEndOfYear.WidthPercent := 100.000000000000000000;
    SetEvent(edEndOfYear, Self, 'OnExit', 'edEndOfYearExit');
    SetEvent(edEndOfYear, Self, 'OnKeyPress', 'edEndOfYearKeyPress');
    edEndOfYear.DataField := 'EndOfYear';
    edEndOfYear.DataSource := DataSource;
    cbOrganisationType.SetParentComponent(Self);
    cbOrganisationType.Name := 'cbOrganisationType';
    cbOrganisationType.Left := 24;
    cbOrganisationType.Top := 276;
    cbOrganisationType.Width := 197;
    cbOrganisationType.Height := 22;
    cbOrganisationType.Hint := 'Organisation Type';
    cbOrganisationType.ElementClassName := 'form-select form-control';
    cbOrganisationType.ElementFont := efCSS;
    cbOrganisationType.ElementPosition := epIgnore;
    cbOrganisationType.HeightStyle := ssAuto;
    cbOrganisationType.HeightPercent := 100.000000000000000000;
    cbOrganisationType.ShowHint := True;
    cbOrganisationType.TabOrder := 12;
    cbOrganisationType.WidthStyle := ssAuto;
    cbOrganisationType.WidthPercent := 100.000000000000000000;
    cbOrganisationType.DataField := 'OrganisationType';
    cbOrganisationType.DataSource := DataSource;
    cbOrganisationType.ListSource := MainData.DummySource;
    cbSubmissionMethod.SetParentComponent(Self);
    cbSubmissionMethod.Name := 'cbSubmissionMethod';
    cbSubmissionMethod.Left := 23;
    cbSubmissionMethod.Top := 360;
    cbSubmissionMethod.Width := 197;
    cbSubmissionMethod.Height := 22;
    cbSubmissionMethod.Hint := 'Submission Method';
    cbSubmissionMethod.ElementClassName := 'form-select form-control';
    cbSubmissionMethod.ElementFont := efCSS;
    cbSubmissionMethod.ElementPosition := epIgnore;
    cbSubmissionMethod.HeightStyle := ssAuto;
    cbSubmissionMethod.HeightPercent := 100.000000000000000000;
    cbSubmissionMethod.ShowHint := True;
    cbSubmissionMethod.TabOrder := 12;
    cbSubmissionMethod.WidthStyle := ssAuto;
    cbSubmissionMethod.WidthPercent := 100.000000000000000000;
    cbSubmissionMethod.DataField := 'SubmissionMethod';
    cbSubmissionMethod.DataSource := DataSource;
    cbSubmissionMethod.ListSource := MainData.DummySource;
    cbGASDSelection.SetParentComponent(Self);
    cbGASDSelection.Name := 'cbGASDSelection';
    cbGASDSelection.Left := 24;
    cbGASDSelection.Top := 332;
    cbGASDSelection.Width := 197;
    cbGASDSelection.Height := 22;
    cbGASDSelection.Hint := 'GASD Selection';
    cbGASDSelection.ElementClassName := 'form-select form-control';
    cbGASDSelection.ElementFont := efCSS;
    cbGASDSelection.ElementPosition := epIgnore;
    cbGASDSelection.HeightStyle := ssAuto;
    cbGASDSelection.HeightPercent := 100.000000000000000000;
    cbGASDSelection.ShowHint := True;
    cbGASDSelection.TabOrder := 12;
    cbGASDSelection.WidthStyle := ssAuto;
    cbGASDSelection.WidthPercent := 100.000000000000000000;
    cbGASDSelection.DataField := 'GASDSelection';
    cbGASDSelection.DataSource := DataSource;
    cbGASDSelection.ListSource := MainData.DummySource;
    EditButton.SetParentComponent(Self);
    EditButton.Name := 'EditButton';
    EditButton.Left := 24;
    EditButton.Top := 433;
    EditButton.Width := 96;
    EditButton.Height := 25;
    EditButton.Caption := 'Edit';
    EditButton.ChildOrder := 13;
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditButton, Self, 'OnClick', 'EditButtonClick');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 228;
    CloseButton.Top := 433;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 14;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 126;
    SaveButton.Top := 433;
    SaveButton.Width := 96;
    SaveButton.Height := 25;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 14;
    SaveButton.Enabled := False;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    edHMRCReference.SetParentComponent(Self);
    edHMRCReference.Name := 'edHMRCReference';
    edHMRCReference.Left := 24;
    edHMRCReference.Top := 388;
    edHMRCReference.Width := 282;
    edHMRCReference.Height := 22;
    edHMRCReference.Hint := 'HMRC Reference';
    edHMRCReference.ChildOrder := 9;
    edHMRCReference.ElementClassName := 'form-control';
    edHMRCReference.ElementPosition := epIgnore;
    edHMRCReference.HeightStyle := ssAuto;
    edHMRCReference.HeightPercent := 100.000000000000000000;
    edHMRCReference.ShowHint := True;
    edHMRCReference.Text := 'edHMRCReference';
    edHMRCReference.WidthStyle := ssAuto;
    edHMRCReference.WidthPercent := 100.000000000000000000;
    edHMRCReference.DataField := 'HMRCRef';
    edHMRCReference.DataSource := DataSource;
    AuthorisedOfficial.SetParentComponent(Self);
    AuthorisedOfficial.Name := 'AuthorisedOfficial';
    AuthorisedOfficial.Left := 24;
    AuthorisedOfficial.Top := 52;
    AuthorisedOfficial.Width := 249;
    AuthorisedOfficial.Height := 22;
    AuthorisedOfficial.ElementClassName := 'form-select form-control';
    AuthorisedOfficial.ElementFont := efCSS;
    AuthorisedOfficial.HeightPercent := 100.000000000000000000;
    AuthorisedOfficial.WidthPercent := 100.000000000000000000;
    SetEvent(AuthorisedOfficial, Self, 'OnChange', 'AuthorisedOfficialChange');
    AuthorisedOfficial.ItemIndex := -1;
    MainDataset.SetParentComponent(Self);
    MainDataset.Name := 'MainDataset';
    MainDataset.AfterOpen := MainDatasetAfterOpen;
    MainDataset.AfterPost := MainDatasetAfterPost;
    MainDataset.EntitySetName := 'Charity';
    MainDataset.Connection := MainData.DataConnection;
    MainDataset.Left := 992;
    MainDataset.Top := 32;
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetHMRCOfficial.SetParentComponent(MainDataset);
    MainDatasetHMRCOfficial.Name := 'MainDatasetHMRCOfficial';
    MainDatasetHMRCOfficial.FieldName := 'HMRCOfficial';
    MainDatasetHMRCRef.SetParentComponent(MainDataset);
    MainDatasetHMRCRef.Name := 'MainDatasetHMRCRef';
    MainDatasetHMRCRef.FieldName := 'HMRCRef';
    MainDatasetHMRCRef.Size := 50;
    MainDatasetCharityType.SetParentComponent(MainDataset);
    MainDatasetCharityType.Name := 'MainDatasetCharityType';
    MainDatasetCharityType.FieldName := 'CharityType';
    MainDatasetCharityType.Required := True;
    MainDatasetCharityType.Size := 15;
    MainDatasetGatewayId.SetParentComponent(MainDataset);
    MainDatasetGatewayId.Name := 'MainDatasetGatewayId';
    MainDatasetGatewayId.FieldName := 'GatewayId';
    MainDatasetGatewayId.Size := 50;
    MainDatasetRegulator.SetParentComponent(MainDataset);
    MainDatasetRegulator.Name := 'MainDatasetRegulator';
    MainDatasetRegulator.FieldName := 'Regulator';
    MainDatasetRegulator.Required := True;
    MainDatasetRegulator.Size := 4;
    MainDatasetOtherRegulator.SetParentComponent(MainDataset);
    MainDatasetOtherRegulator.Name := 'MainDatasetOtherRegulator';
    MainDatasetOtherRegulator.FieldName := 'OtherRegulator';
    MainDatasetOtherRegulator.Size := 250;
    MainDatasetRegulatorNumber.SetParentComponent(MainDataset);
    MainDatasetRegulatorNumber.Name := 'MainDatasetRegulatorNumber';
    MainDatasetRegulatorNumber.FieldName := 'RegulatorNumber';
    MainDatasetRegulatorNumber.Size := 50;
    MainDatasetEndOfYear.SetParentComponent(MainDataset);
    MainDatasetEndOfYear.Name := 'MainDatasetEndOfYear';
    MainDatasetEndOfYear.FieldName := 'EndOfYear';
    MainDatasetEndOfYear.Size := 5;
    MainDatasetGatewayPassword.SetParentComponent(MainDataset);
    MainDatasetGatewayPassword.Name := 'MainDatasetGatewayPassword';
    MainDatasetGatewayPassword.FieldName := 'GatewayPassword';
    MainDatasetGatewayPassword.Size := 64;
    MainDatasetOrganisationType.SetParentComponent(MainDataset);
    MainDatasetOrganisationType.Name := 'MainDatasetOrganisationType';
    MainDatasetOrganisationType.FieldName := 'OrganisationType';
    MainDatasetOrganisationType.Required := True;
    MainDatasetOrganisationType.Size := 16;
    MainDatasetOrganisationName.SetParentComponent(MainDataset);
    MainDatasetOrganisationName.Name := 'MainDatasetOrganisationName';
    MainDatasetOrganisationName.FieldName := 'OrganisationName';
    MainDatasetOrganisationName.Size := 250;
    MainDatasetSubmissionMethod.SetParentComponent(MainDataset);
    MainDatasetSubmissionMethod.Name := 'MainDatasetSubmissionMethod';
    MainDatasetSubmissionMethod.FieldName := 'SubmissionMethod';
    MainDatasetSubmissionMethod.Required := True;
    MainDatasetSubmissionMethod.Size := 4;
    MainDatasetRegulatorStatus.SetParentComponent(MainDataset);
    MainDatasetRegulatorStatus.Name := 'MainDatasetRegulatorStatus';
    MainDatasetRegulatorStatus.FieldName := 'RegulatorStatus';
    MainDatasetRegulatorStatus.Required := True;
    MainDatasetRegulatorStatus.Size := 8;
    MainDatasetGASDSelection.SetParentComponent(MainDataset);
    MainDatasetGASDSelection.Name := 'MainDatasetGASDSelection';
    MainDatasetGASDSelection.FieldName := 'GASDSelection';
    MainDatasetGASDSelection.Size := 18;
    MainDatasetLockDate.SetParentComponent(MainDataset);
    MainDatasetLockDate.Name := 'MainDatasetLockDate';
    MainDatasetLockDate.FieldName := 'LockDate';
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := MainDataset;
    SetEvent(DataSource, Self, 'OnStateChange', 'DataSourceStateChange');
    DataSource.Left := 1088;
    DataSource.Top := 32;
  finally
    edOrganisationName.AfterLoadDFMValues;
    cbCharityType.AfterLoadDFMValues;
    edGatewayId.AfterLoadDFMValues;
    cbRegulator.AfterLoadDFMValues;
    edOtherRegulator.AfterLoadDFMValues;
    edRegulatorNumber.AfterLoadDFMValues;
    edEndOfYear.AfterLoadDFMValues;
    cbOrganisationType.AfterLoadDFMValues;
    cbSubmissionMethod.AfterLoadDFMValues;
    cbGASDSelection.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    edHMRCReference.AfterLoadDFMValues;
    AuthorisedOfficial.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetHMRCOfficial.AfterLoadDFMValues;
    MainDatasetHMRCRef.AfterLoadDFMValues;
    MainDatasetCharityType.AfterLoadDFMValues;
    MainDatasetGatewayId.AfterLoadDFMValues;
    MainDatasetRegulator.AfterLoadDFMValues;
    MainDatasetOtherRegulator.AfterLoadDFMValues;
    MainDatasetRegulatorNumber.AfterLoadDFMValues;
    MainDatasetEndOfYear.AfterLoadDFMValues;
    MainDatasetGatewayPassword.AfterLoadDFMValues;
    MainDatasetOrganisationType.AfterLoadDFMValues;
    MainDatasetOrganisationName.AfterLoadDFMValues;
    MainDatasetSubmissionMethod.AfterLoadDFMValues;
    MainDatasetRegulatorStatus.AfterLoadDFMValues;
    MainDatasetGASDSelection.AfterLoadDFMValues;
    MainDatasetLockDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
  end;
end;

end.
